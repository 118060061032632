<template>
  <div class="research-display">
    <div class="research-bar">
      <!--button
        :id="selectionClient"
        @click="
          resetLimit();
          getClients();
        "
        class="hide-small-screen"
      >
        Client
      </button-->
      <button
        :id="selectionTravel"
        @click="
          resetLimit();
          getTravel();
        "
        class="hide-small-screen"
      >
        Travel
      </button>
      <!--button
        :id="selectionAgency"
        @click="
          resetLimit();
          getAgency();
        "
        class="hide-small-screen"
      >
        Agency
      </button>
      <button
        :id="selectionEmployee"
        @click="
          resetLimit();
          getEmployee();
        "
        class="hide-small-screen"
      >
        Agency Employee
      </button>
      <button
        :id="selectionVoucher"
        @click="
          resetLimit();
          getVoucher();
        "
        class="hide-small-screen"
      >
        Voucher
      </button>
      <button :id="selectionAgent" @click="getAgent" class="hide-small-screen">
        Agent
      </button>
      <button
        :id="selectionTravelAssist"
        @click="
          resetLimit();
          getTravelAssist();
        "
        class="hide-small-screen"
      >
        Travel Assister
      </button-->
      <button
        v-if="isSupervisor"
        :id="selectionTicket"
        @click="
          resetLimit();
          getTicket();
        "
        class="hide-small-screen"
      >
        Ticket
      </button>
      <select class="hide-big-screen" @change="onChange()" v-model="mobileBar">
        <option value="client">Client</option>
        <option value="travel">Travel</option>
        <option value="agency">Agency</option>
        <option value="employee">Agency Employee</option>
        <option value="voucher">Voucher</option>
        <option value="agent">Agent</option>
        <option value="travelassist">TravelAssister</option>
        <option value="ticket">Ticket</option>
      </select>
      <input
        type="text"
        class="research-input"
        maxlength="256"
        v-model="researchInput"
        @keypress.enter="startSearch()"
      />
      <button class="go-button" @click="startSearch()">GO!</button>
    </div>
    <div class="filters-sort">
      <div class="filters">
        <div
          class="filters-title filters-item"
          v-if="
            selected === 'client' ||
            selected === 'travel' ||
            selected === 'employee' ||
            selected === 'voucher' ||
            selected === 'ticket'
          "
        >
          <span>Filters : </span>
        </div>
        <!-- Client filters -->
        <div class="filters-client" v-if="selected === 'client'">
          <div class="filters-item">
            <label for="client-agency">Travel Agency :</label>
            <select id="client-agency" v-model="filters.client.agency">
              <option selected value="">None</option>
              <option
                v-bind:value="agency._id"
                v-for="agency in agencies"
                :key="agency._id"
              >
                {{ agency.name }}
              </option>
            </select>
          </div>
          <div class="filters-item">
            <label for="travelling">Travelling :</label>
            <input
              type="checkbox"
              id="travelling"
              v-model="filters.client.travelling"
            />
          </div>
        </div>
        <!-- Travel filters -->
        <div class="filters-travel" v-if="selected === 'travel'">
          <div class="filters-item">
            <label for="travel-agency">Travel Agency :</label>
            <select id="travel-agency" v-model="filters.travel.agency">
              <option selected value="">None</option>
              <option
                v-bind:value="agency._id"
                v-for="agency in agencies"
                :key="agency._id"
              >
                {{ agency.name }}
              </option>
            </select>
          </div>
          <div class="filters-item">
            <label for="travel-ongoing">Ongoing :</label>
            <input
              type="checkbox"
              id="travel-ongoing"
              v-model="filters.travel.ongoing"
            />
          </div>
          <div class="filters-item">
            <label for="travel-start">Start date :</label>
            <input
              id="travel-start"
              type="date"
              min="2020-01-01"
              v-model="filters.travel.startDate"
            />
          </div>
          <div class="filters-item">
            <label for="travel-end">End date :</label>
            <input
              id="travel-end"
              type="date"
              min="2020-01-01"
              v-model="filters.travel.endDate"
            />
          </div>
        </div>
        <!-- Agency Employee filters -->
        <div class="filters-agency-employee" v-if="selected === 'employee'">
          <div class="filters-item">
            <label for="employee-agency">Travel Agency :</label>
            <select id="employee-agency" v-model="filters.employee.agency">
              <option selected value="">None</option>
              <option
                v-bind:value="agency._id"
                v-for="agency in agencies"
                :key="agency._id"
              >
                {{ agency.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- Voucher filters -->
        <div class="filters-voucher" v-if="selected === 'voucher'">
          <div class="filters-item">
            <label for="voucher-available">Available :</label>
            <input
              id="voucher-available"
              type="checkbox"
              v-model="filters.voucher.available"
            />
          </div>
          <div class="filters-item">
            <label for="voucher-start">Voucher start :</label>
            <input
              id="voucher-start"
              type="date"
              min="2020-01-01"
              v-model="filters.voucher.startDate"
            />
          </div>
          <div class="filters-item">
            <label for="voucher-end">Voucher end :</label>
            <input
              id="voucher-end"
              type="date"
              min="2020-01-01"
              v-model="filters.voucher.endDate"
            />
          </div>
        </div>
        <!-- Ticket filters -->
        <div class="filters-ticket" v-if="selected === 'ticket'">
          <div class="filters-item">
            <label for="ticket-state">Ticket state :</label>
            <select id="ticket-state" v-model="filters.ticket.state">
              <option value="pending">Pending</option>
              <option value="closed">Closed</option>
              <option value="transfer">Transfer</option>
              <option value="answer">Answer</option>
            </select>
          </div>
        </div>
        <div
          class="filters-item"
          v-if="
            selected === 'client' ||
            selected === 'travel' ||
            selected === 'employee' ||
            selected === 'voucher' ||
            selected === 'ticket'
          "
        >
          <button id="apply-filters" v-on:click="applyFilters()">
            Apply filters
          </button>
          <button id="reset-filters" v-on:click="resetFilters" v-if="filtering">
            Reset Filters
          </button>
        </div>
      </div>
      <div
        class="sort"
        v-if="
          selected === 'client' ||
          selected === 'travel' ||
          selected === 'employee' ||
          selected === 'voucher' ||
          selected === 'ticket'
        "
      >
        <label for="sorting-select">Sort :</label>
        <select id="sorting-select" v-model="sortRule" v-on:change="applySort">
          <option value="default" selected>Default</option>
          <option
            value="sort-by-agency"
            v-if="
              selected === 'client' ||
              selected === 'travel' ||
              selected === 'employee'
            "
          >
            Sort by Agency
          </option>
          <option value="available-first" v-if="selected === 'voucher'">
            Available First
          </option>
          <option
            value="start-date"
            v-if="selected === 'travel' || selected === 'voucher'"
          >
            Sort by start date
          </option>
          <option
            value="end-date"
            v-if="selected === 'travel' || selected === 'voucher'"
          >
            Sort by end date
          </option>
          <option value="last-updated" v-if="selected === 'ticket'">
            Last updated first
          </option>
        </select>
      </div>
    </div>
    <div class="result-display">
      <result
        v-for="result in transformToTask"
        :key="result.id"
        :title="result.title"
        :display1="result.display1"
        :display2="result.display2"
        :display3="result.display3"
        :tags="result.tags"
      ></result>
      <span class="empty-result" v-if="!results.length">No results found</span>
    </div>
    <div class="show-more" v-if="checkLimit()">
      <button v-on:click="expandLimit">Show more elements</button>
    </div>
  </div>
</template>

<script>
import result from "./Result.vue";
import HandleApi from "../apiHandle";
export default {
  name: "SearchAgent",
  components: {
    result,
  },
  data() {
    return {
      results: [],
      researchInput: "",
      selected: "travel",
      mobileBar: "travel",
      resultsLimit: 100,
      agencies: [],
      filtering: false,
      sorting: false,
      sortRule: "",
      searching: false,
      test: [],
      isSupervisor: false,
      filters: {
        /*client: {
          agency: "",
          travelling: false,
        },*/
        travel: {
          agency: "",
          ongoing: false,
          startDate: 0,
          endDate: 0,
        },
        /*employee: {
          agency: "",
        },
        voucher: {
          available: "",
          startDate: 0,
          endDate: 0,
        },*/
        ticket: {
          state: "",
        },
      },
    };
  },
  async mounted() {
    const agent = await HandleApi.getAgent("/" + this.$store.getters.userId);
    this.isSupervisor = agent.isSupervisor;
  },
  computed: {
    transformToTask: function () {
      let res = [];
      let now = new Date();
      if (this.selected == "client") {
        for (let i = 0; i < this.results.length; i++) {
          try {
            let element = this.results[i];
            let messageApp = "";
            if (
              element.hasOwnProperty("messageApp") &&
              element.messageApp.length > 0 &&
              element.messageApp[0].hasOwnProperty("app")
            ) {
              if (element.messageApp.length > 1) {
                messageApp =
                  element.messageApp[0].app +
                  " +" +
                  (element.messageApp.length - 1).toString();
              } else {
                messageApp = element.messageApp[0].app;
              }
            }
            let agencyName = "";
            let agency_id = null;
            if (
              element.hasOwnProperty("tourAgency") &&
              element.tourAgency.hasOwnProperty("name")
            ) {
              agencyName = element.tourAgency.name
                .replace(/&amp;/gi, "&")
                .replace(/&#x27;/gi, "'");
              agency_id = element.tourAgency._id;
            }
            let tag = { travelling: false, planned: false };
            for (let i = 0; i < element.travel.length; i++) {
              if (new Date(element.travel[i].startDate) > now) {
                tag.planed = true;
              } else {
                if (new Date(element.travel[i].endDate) >= now) {
                  tag.travelling = true;
                }
              }
            }
            var tag_list = [];
            if (tag.travelling) {
              tag_list.push({ text: "Travelling", color: "red" });
            }
            if (tag.planned) {
              tag_list.push({ text: "Travel Planned", color: "white" });
            }
            res.push({
              title: {
                text:
                  this.capitalizeFirstLetter(element.name) +
                  " " +
                  this.capitalizeFirstLetter(element.surname),
                id: "/client/" + element._id,
              },
              display1: {
                text: element.email,
              },
              display2: {
                text: messageApp,
              },
              display3: {
                text: this.capitalizeFirstLetter(agencyName),
                id: "/agency/" + agency_id,
              },
              tags: tag_list,
            });
          } catch {
            continue;
          }
        }
      } else {
        if (this.selected == "travel") {
          for (let i = 0; i < this.results.length; i++) {
            try {
              let element = this.results[i];
              let client_name = "";
              let client_id = null;
              if (element.client.length > 0) {
                client_name =
                  this.capitalizeFirstLetter(element.client[0].client_id.name) +
                  " " +
                  this.capitalizeFirstLetter(
                    element.client[0].client_id.surname
                  );
                client_id = element.client[0].client_id._id;
              }
              let tag = [];
              if (now < new Date(element.startDate)) {
                tag.push({ text: "Incoming", color: "green" });
              } else {
                if (
                  now.getTime() <
                  new Date(element.endDate).getTime() + 86400000
                ) {
                  tag.push({ text: "In Progress", color: "orange" });
                } else {
                  tag.push({ text: "Ended", color: "red" });
                }
              }
              if (element.hasOwnProperty("tourAgency")) {
                tag.push({ text: element.tourAgency.name, color: "purple" });
              }
              res.push({
                title: {
                  text: this.capitalizeFirstLetter(element.name),
                  id: "/travel/" + element._id,
                },
                display1: {
                  text:
                    new Date(element.startDate).toDateString() +
                    " - " +
                    new Date(element.endDate).toDateString(),
                },
                display2: {
                  text: "",
                },
                display3: {
                  text: client_name,
                  id: "/client/" + client_id,
                },
                tags: tag,
              });
            } catch {
              continue;
            }
          }
        }
        if (this.selected == "agency") {
          for (let i = 0; i < this.results.length; i++) {
            try {
              let element = this.results[i];
              let tag = [];
              if (element.preAgency) {
                tag.push({
                  text: "Pre Agency",
                  color: "#0D5EDE",
                });
              }
              if (element.preAgency && !element.TAValidation) {
                tag.push({ text: "Waiting Validation", color: "orange" });
              }
              if (
                (element.preAgency && element.TAValidation) ||
                (!element.preAgency &&
                  !element.contractSigned &&
                  element.TAValidation &&
                  element.contractInfo.informationFilled)
              ) {
                tag.push({
                  text: "Waiting contract signing",
                  color: "#FFCB21",
                });
              }
              if (
                !element.preAgency &&
                !element.contractSigned &&
                !element.TAValidation &&
                !element.contractInfo.informationFilled
              ) {
                tag.push({
                  text: "Contract information needed",
                  color: "#FF4500",
                });
              }
              res.push({
                title: {
                  text: this.capitalizeFirstLetter(element.name)
                    .replace(/&amp;/gi, "&")
                    .replace(/&#x27;/gi, "'"),
                  id: element.preAgency
                    ? "/preagency/validation/" + element._id
                    : "/agency/" + element._id,
                },
                display1: {
                  text: element.email,
                },
                display2: {
                  text: "",
                },
                display3: {
                  text:
                    this.capitalizeFirstLetter(element.administrator.name) +
                    " " +
                    this.capitalizeFirstLetter(element.administrator.surname),
                  id: "/employee/" + element.administrator._id,
                },
                tags: tag,
              });
            } catch {
              continue;
            }
          }
        }
        if (this.selected == "voucher") {
          for (let i = 0; i < this.results.length; i++) {
            try {
              let element = this.results[i];
              let tag = [];
              if (
                now <= new Date(element.endDate) &&
                now >= element.startDate
              ) {
                tag.push({ text: "Available", color: "green" });
              } else {
                tag.push({ text: "Not Available", color: "red" });
              }
              res.push({
                title: {
                  text: this.capitalizeFirstLetter(element.name),
                  id: "/voucher/" + element._id,
                },
                display1: {
                  text: element.code.toUpperCase(),
                },
                display2: {
                  text:
                    new Date(element.startDate).toDateString() +
                    " - " +
                    new Date(element.endDate).toDateString(),
                },
                display3: {
                  text: "",
                  id: null,
                },
                tags: tag,
              });
            } catch {
              continue;
            }
          }
        }
        if (this.selected == "employee") {
          for (let i = 0; i < this.results.length; i++) {
            try {
              let element = this.results[i];
              let tag = [];
              if (element.tourAgency.administrator == element._id) {
                tag.push({ text: "Administrator", color: "purple" });
              }
              res.push({
                title: {
                  text:
                    this.capitalizeFirstLetter(element.name) +
                    " " +
                    this.capitalizeFirstLetter(element.surname),
                  id: "/employee/" + element._id,
                },
                display1: {
                  text: element.email,
                },
                display2: {
                  text: "",
                },
                display3: {
                  text: this.capitalizeFirstLetter(element.tourAgency.name),
                  id: "/agency/" + element.tourAgency._id,
                },
                tags: tag,
              });
            } catch {
              continue;
            }
          }
        }
        if (this.selected == "agent") {
          for (let i = 0; i < this.results.length; i++) {
            try {
              let element = this.results[i];
              let tag = [];
              res.push({
                title: {
                  text: this.capitalizeFirstLetter(element.username),
                  id: "/agent/" + element._id,
                },
                display1: {
                  text: this.capitalizeFirstLetter(element.contactCenter),
                },
                display2: {
                  text: "",
                },
                display3: {
                  text: "",
                  id: null,
                },
                tags: tag,
              });
            } catch {
              continue;
            }
          }
        }
        if (this.selected == "travelassist") {
          for (let i = 0; i < this.results.length; i++) {
            try {
              let element = this.results[i];
              res.push({
                title: {
                  text:
                    this.capitalizeFirstLetter(element.name) +
                    " " +
                    this.capitalizeFirstLetter(element.surname),
                  id: "/travelassist/" + element._id,
                },
                display1: {
                  text: element.email,
                },
                display2: {
                  text: this.capitalizeFirstLetter(element.role),
                },
                display3: {
                  text: "",
                  id: null,
                },
                tags: [],
              });
            } catch {
              continue;
            }
          }
        }
        if (this.selected == "ticket") {
          for (let i = 0; i < this.results.length; i++) {
            try {
              let element = this.results[i];
              let tags = [];
              switch (element.status) {
                case "pending":
                  tags.push({ text: "pending", color: "#008467" });
                  break;
                case "answer":
                  tags.push({ text: "answer", color: "#009fbc" });
                  break;
                case "transfer":
                  tags.push({ text: "transfer", color: "#00909f" });
                  break;
                case "closed":
                  tags.push({ text: "closed", color: "#aa1853" });
                  break;
              }
              if (!element.handled && element.status != "closed") {
                tags.push({ text: "not handled", color: "#ddd41f" });
              }
              let title =
                "Support " +
                this.capitalizeFirstLetter(element.clients[0].name) +
                " " +
                this.capitalizeFirstLetter(element.clients[0].surname);
              if (element.hasOwnProperty("travel")) {
                title = element.travel.name;
              }
              let tourAgency = element.hasOwnProperty("tourAgency")
                ? element.tourAgency.name
                : "";
              let tourAgencyId = element.hasOwnProperty("tourAgency")
                ? element.tourAgency._id
                : null;
              res.push({
                title: {
                  text: title,
                  id: "/ticket/" + element._id,
                },
                display1: {
                  text: this.$d(new Date(element.startDate), "long"),
                },
                display2: {
                  text: "",
                },
                display3: {
                  text: tourAgency,
                  id: tourAgencyId,
                },
                tags: tags,
              });
            } catch {
              continue;
            }
          }
        }
      }
      return res;
    },
    selectionClient: function () {
      if (this.selected == "client") {
        return "selectedButton";
      }
      return "normalButton";
    },
    selectionTravel: function () {
      if (this.selected == "travel") {
        return "selectedButton";
      }
      return "normalButton";
    },
    selectionAgency: function () {
      if (this.selected == "agency") {
        return "selectedButton";
      }
      return "normalButton";
    },
    selectionVoucher: function () {
      if (this.selected == "voucher") {
        return "selectedButton";
      }
      return "normalButton";
    },
    selectionAgent: function () {
      if (this.selected == "agent") {
        return "selectedButton";
      }
      return "normalButton";
    },
    selectionEmployee: function () {
      if (this.selected == "employee") {
        return "selectedButton";
      }
      return "normalButton";
    },
    selectionTravelAssist: function () {
      if (this.selected == "travelassist") {
        return "selectedButton";
      }
      return "normalButton";
    },
    selectionTicket: function () {
      if (this.selected == "ticket") {
        return "selectedButton";
      }
      return "normalButton";
    },
  },
  async created() {
    //this.getClients();
    this.getTravel();
  },
  methods: {
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    onChange() {
      this.resultsLimit = 100;
      switch (this.mobileBar) {
        case "client":
          this.getClients();
          break;
        case "travel":
          this.getTravel();
          break;
        case "agency":
          this.getAgency();
          break;
        case "employee":
          this.getEmployee();
          break;
        case "voucher":
          this.getVoucher();
          break;
        case "agent":
          this.getAgent();
          break;
        case "travelassist":
          this.getTravelAssist();
          break;
        case "ticket":
          this.getTicket();
          break;
      }
    },
    getClients: async function () {
      let query = "?limit=" + this.resultsLimit;
      try {
        this.results = await HandleApi.getClients(query);
        this.getAgencies();
        this.selected = "client";
        this.resultsLimit = 100;
        this.searching = false;
        this.filtering = false;
        this.sorting = false;
      } catch (err) {
        return err;
      }
    },
    getTravel: async function () {
      let query = "?limit=" + this.resultsLimit;
      try {
        this.results = await HandleApi.getTravel(query);
        this.getAgencies();
        this.selected = "travel";
        this.resultsLimit = 100;
        this.searching = false;
        this.filtering = false;
        this.sorting = false;
      } catch (err) {
        return err;
      }
    },
    getAgency: async function () {
      let query = "?limit=" + this.resultsLimit;
      try {
        this.results = await HandleApi.getAgency(query);
        let temp = await HandleApi.getPreAgency();
        for (let i = 0; i < temp.length; i++) {
          this.results.push({
            name: temp[i].agencyName,
            email: temp[i].agencyEmail,
            administrator: { name: temp[i].name, surname: temp[i].surname },
            TAValidation: temp[i].TAValidation,
            preAgency: true,
            _id: temp[i]._id,
          });
        }

        this.selected = "agency";
        this.resultsLimit = 100;
        this.searching = false;
        this.filtering = false;
        this.sorting = false;
      } catch (err) {
        return err;
      }
    },
    getVoucher: async function () {
      let query = "?limit=" + this.resultsLimit;
      try {
        this.results = await HandleApi.getVoucher(query);
        this.selected = "voucher";
        this.resultsLimit = 100;
        this.searching = false;
        this.filtering = false;
        this.sorting = false;
      } catch (err) {
        return err;
      }
    },
    getAgent: async function () {
      let query = "?limit=" + this.resultsLimit;
      try {
        this.results = await HandleApi.getAgent(query);
        this.selected = "agent";
        this.resultsLimit = 100;
        this.searching = false;
        this.filtering = false;
        this.sorting = false;
      } catch (err) {
        return err;
      }
    },
    getEmployee: async function () {
      let query = "?limit=" + this.resultsLimit;
      try {
        this.results = await HandleApi.getEmployee(query);
        this.getAgencies();
        this.selected = "employee";
        this.resultsLimit = 100;
        this.searching = false;
        this.filtering = false;
        this.sorting = false;
      } catch (err) {
        return err;
      }
    },
    getTravelAssist: async function () {
      let query = "?limit=" + this.resultsLimit;
      try {
        this.results = await HandleApi.getTravelAssist(query);
        this.selected = "travelassist";
        this.resultsLimit = 100;
        this.searching = false;
        this.filtering = false;
        this.sorting = false;
      } catch (err) {
        return err;
      }
    },
    getTicket: async function () {
      let query = "?limit=" + this.resultsLimit;
      try {
        this.results = await HandleApi.getTicket(query);
        this.selected = "ticket";
        this.resultsLimit = 100;
        this.searching = false;
        this.filtering = false;
        this.sorting = false;
      } catch (err) {
        return err;
      }
    },
    searchClients: async function () {
      try {
        this.results = await HandleApi.searchClients(this.researchInput);
      } catch (err) {
        return err;
      }
    },
    searchTravel: async function () {
      try {
        this.results = await HandleApi.searchTravel(this.researchInput);
      } catch (err) {
        return err;
      }
    },
    searchAgency: async function () {
      try {
        this.results = await HandleApi.searchAgency(this.researchInput);
      } catch (err) {
        return err;
      }
    },
    searchEmployee: async function () {
      try {
        this.results = await HandleApi.searchEmployee(this.researchInput);
      } catch (err) {
        return err;
      }
    },
    searchVoucher: async function () {
      try {
        this.results = await HandleApi.searchVoucher(this.researchInput);
      } catch (err) {
        return err;
      }
    },
    searchAgent: async function () {
      try {
        this.results = await HandleApi.searchAgent(this.researchInput);
      } catch (err) {
        return err;
      }
    },
    searchTravelAssist: async function () {
      try {
        this.results = await HandleApi.searchTravelAssist(this.researchInput);
      } catch (err) {
        return err;
      }
    },
    searchTicket: async function () {
      try {
        this.results = await HandleApi.searchTicket(this.researchInput);
      } catch (err) {
        return err;
      }
    },
    startSearch: function () {
      if (this.researchInput.replace(/\s/g, "") != "") {
        this.searching = true;
        this.filterQuery();
      } else {
        this.searching = false;
        switch (this.selected) {
          case "client":
            this.getClients();
            break;
          case "travel":
            this.getTravel();
            break;
          case "agency":
            this.getAgency();
            break;
          case "employee":
            this.getEmployee();
            break;
          case "voucher":
            this.getVoucher();
            break;
          case "agent":
            this.getAgent();
            break;
          case "travelassist":
            this.getTravelAssist();
            break;
          case "ticket":
            this.getTicket();
            break;
        }
      }
    },
    expandLimit: async function () {
      this.resultsLimit = this.resultsLimit + 100;
      let query = "?limit=" + this.resultsLimit;
      if (this.filtering || this.searching || this.sorting) {
        this.filterQuery();
      } else {
        switch (this.selected) {
          case "client":
            try {
              this.results = await HandleApi.getClients(query);
            } catch (err) {
              return err;
            }
            break;
          case "travel":
            try {
              this.results = await HandleApi.getTravel(query);
            } catch (err) {
              return err;
            }
            break;
          case "agency":
            try {
              this.results = await HandleApi.getAgency(query);
              let temp = await HandleApi.getPreAgency();
              for (let i = 0; i < temp.length; i++) {
                this.results.push({
                  name: temp[i].agencyName,
                  email: temp[i].agencyEmail,
                  administrator: {
                    name: temp[i].name,
                    surname: temp[i].surname,
                  },
                  TAValidation: temp[i].TAValidation,
                  preAgency: true,
                  _id: temp[i]._id,
                });
              }
            } catch (err) {
              return err;
            }
            break;
          case "employee":
            try {
              this.results = await HandleApi.getEmployee(query);
            } catch (err) {
              return err;
            }
            break;
          case "voucher":
            try {
              this.results = await HandleApi.getVoucher(query);
            } catch (err) {
              return err;
            }
            break;
          case "agent":
            try {
              this.results = await HandleApi.getAgent(query);
            } catch (err) {
              return err;
            }
            break;
          case "travelassist":
            try {
              this.results = await HandleApi.getTravelAssist(query);
            } catch (err) {
              return err;
            }
            break;
          case "ticket":
            try {
              this.results = await HandleApi.getTicket(query);
            } catch (err) {
              return err;
            }
            break;
        }
      }
    },
    resetLimit: function () {
      this.resultsLimit = 100;
    },
    checkLimit: function () {
      if (
        (this.results.length !== 0 && this.results.length % 100 == 0) ||
        this.selected == "agency"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getAgencies: async function () {
      this.agencies = await HandleApi.getAgency("?limit=500");
    },
    applySort: function () {
      if (this.sortRule !== "default") {
        this.sorting = true;
        this.filterQuery();
      } else {
        this.sorting = false;
      }
    },
    applyFilters: function () {
      this.filtering = true;
      this.filterQuery();
    },
    resetFilters: async function () {
      this.filtering = false;
      if (this.searching || this.sorting) {
        this.filterQuery();
      } else {
        let query = "?limit=" + this.resultsLimit;
        switch (this.selected) {
          case "client":
            this.filters.client.agency = "";
            this.filters.client.travelling = false;
            try {
              this.results = await HandleApi.getClients(query);
            } catch (err) {
              return err;
            }
            break;
          case "travel":
            this.filters.travel.agency = "";
            this.filters.travel.ongoing = "";
            this.filters.travel.startDate = 0;
            this.filters.travel.endDate = 0;
            try {
              this.results = await HandleApi.getTravel(query);
            } catch (err) {
              return err;
            }
            break;
          case "agency":
            try {
              this.results = await HandleApi.getAgency(query);
            } catch (err) {
              return err;
            }
            break;
          case "employee":
            this.filters.employee.agency = "";
            try {
              this.results = await HandleApi.getEmployee(query);
            } catch (err) {
              return err;
            }
            break;
          case "voucher":
            this.filters.voucher.available = "";
            this.filters.voucher.startDate = 0;
            this.filters.voucher.endDate = 0;
            try {
              this.results = await HandleApi.getVoucher(query);
            } catch (err) {
              return err;
            }
            break;
          case "agent":
            try {
              this.results = await HandleApi.getAgent(query);
            } catch (err) {
              return err;
            }
            break;
          case "travelassist":
            try {
              this.results = await HandleApi.getTravelAssist(query);
            } catch (err) {
              return err;
            }
            break;
          case "ticket":
            try {
              this.results = await HandleApi.getTicket(query);
            } catch (err) {
              return err;
            }
            break;
        }
      }
    },

    filterQuery: async function () {
      let query = "?limit=" + this.resultsLimit;
      if (this.filtering) {
        query += "&filtering=" + true;
        switch (this.selected) {
          case "client":
            if (
              this.filters.client.agency !== "" ||
              this.filters.client.travelling
            ) {
              if (this.filters.client.agency !== "")
                query += "&agency=" + this.filters.client.agency;
              if (this.filters.client.travelling)
                query += "&travelling=" + true;
            }
            break;
          case "travel":
            if (
              this.filters.travel.agency !== "" ||
              this.filters.travel.ongoing ||
              this.filters.travel.startDate !== 0 ||
              this.filters.travel.endDate !== 0
            ) {
              if (this.filters.travel.agency !== "")
                query += "&agency=" + this.filters.travel.agency;
              if (this.filters.travel.ongoing) query += "&ongoing=" + true;
              if (this.filters.travel.startDate !== 0)
                query += "&startDate=" + this.filters.travel.startDate;
              if (this.filters.travel.endDate !== 0)
                query += "&endDate=" + this.filters.travel.endDate;
            }
            break;
          case "employee":
            if (
              this.filters.employee.agency !== "" ||
              this.filters.employee.administrator
            ) {
              if (this.filters.employee.agency !== "")
                query += "&agency=" + this.filters.employee.agency;
              if (this.filters.employee.administrator)
                query += "&administrator=" + true;
            }
            break;
          case "voucher":
            if (
              this.filters.voucher.available ||
              this.filters.voucher.startDate !== 0 ||
              this.filters.voucher.endDate !== 0
            ) {
              if (this.filters.voucher.available) query += "&available=" + true;
              if (this.filters.voucher.startDate !== 0)
                query += "&startDate=" + this.filters.voucher.startDate;
              if (this.filters.voucher.endDate !== 0)
                query += "&endDate=" + this.filters.voucher.endDate;
            }
            break;
          case "ticket":
            if (this.filters.ticket.state !== "") {
              query += "&state=" + this.filters.ticket.state;
            }
        }
      }
      if (this.searching) {
        query += "&searching=" + true;
        query += "&keywords=" + encodeURIComponent(this.researchInput);
      }
      if (this.sorting) {
        query += "&sortrule=" + this.sortRule;
      }
      switch (this.selected) {
        case "client":
          try {
            this.results = await HandleApi.searchClients(query);
          } catch (err) {
            return err;
          }
          break;
        case "travel":
          try {
            this.results = await HandleApi.searchTravel(query);
          } catch (err) {
            return err;
          }
          break;
        case "agency":
          try {
            this.results = await HandleApi.searchAgency(query);
          } catch (err) {
            return err;
          }
          break;
        case "employee":
          try {
            this.results = await HandleApi.searchEmployee(query);
          } catch (err) {
            return err;
          }
          break;
        case "voucher":
          try {
            this.results = await HandleApi.searchVoucher(query);
          } catch (err) {
            return err;
          }
          break;
        case "agent":
          try {
            this.results = await HandleApi.searchAgent(query);
          } catch (err) {
            return err;
          }
          break;
        case "travelassist":
          try {
            this.results = await HandleApi.searchTravelAssist(query);
          } catch (err) {
            return err;
          }
          break;
        case "ticket":
          try {
            this.results = await HandleApi.searchTicket(query);
          } catch (err) {
            return err;
          }
          break;
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1000px) {
  .research-display {
    width: 90%;
    margin: 0 auto;
  }

  .research-bar {
    margin-top: 10px;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: repeat(8, 1fr) 4fr 50px;
    grid-column-gap: 10px;
  }

  .research-bar .go-button {
    padding: 0;
    border-radius: 10px;
    border: none;
  }

  .research-bar #normalButton {
    border: 2px solid grey;
    cursor: pointer;
  }

  .research-bar #selectedButton {
    border: 2px solid red;
    cursor: pointer;
  }

  .hide-big-screen {
    display: none;
  }
}

@media only screen and (max-width: 999px) {
  .research-display {
    width: 100%;
    margin: 0 auto;
  }

  .research-bar {
    margin-top: 10px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 50px;
    grid-column-gap: 10px;
  }

  .research-input {
    min-width: 0;
  }

  .research-bar .go-button {
    padding: 0;
    border-radius: 10px;
    border: none;
  }

  .hide-small-screen {
    display: none;
  }
}

.result-display {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-items: center;
}

.filters-sort {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filters-title {
  font-weight: bold;
}

#reset-filters,
#apply-filters {
  margin: 0 5px;
}

.filters,
.sort,
.filters-client,
.filters-travel,
.filters-agency-employee,
.filters-voucher,
.filters-ticket {
  display: flex;
  flex-direction: row;
}
.filters-item {
  margin-right: 15px;
}
.sort label {
  font-weight: bold;
}
.show-more {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.show-more button {
  width: 80%;
  background: #9ad3d1;
  border: none;
  border-radius: 40px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.show-more button:hover {
  background: #86bebc;
}

@media (max-width: 1000px) {
  .filters-sort {
    flex-direction: column;
    margin-left: 30px;
  }

  .filters,
  .filters-client,
  .filters-travel,
  .filters-agency-employee,
  .filters-voucher,
  .filters-ticket {
    flex-direction: column;
  }

  .filters-item {
    margin: 5px 0;
  }

  .filters-title,
  .sort label {
    font-size: 150%;
  }
}
</style>
