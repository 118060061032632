var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-container",style:({
    color: _vm.$store.getters.color.fontColor,
    backgroundColor: _vm.$store.getters.color.color1,
  })},[_c('router-link',{staticClass:"home-title",attrs:{"to":"/"}},[(_vm.$store.getters.agencyPic)?_c('img',{staticClass:"agency-pic",attrs:{"src":_vm.$store.getters.agencyPic,"height":"70","width":"70"}}):_vm._e(),_c('span',{staticClass:"navbar-title"},[_vm._v(" "+_vm._s(_vm.$store.getters.tourAgencyName.replace(/&/g, "&"))+" ")])]),_c('div',{staticClass:"navbar-right-display"},[_c('div',{staticClass:"language-selection"},[_c('v-select',{staticClass:"language-chooser",attrs:{"options":_vm.options,"clearable":false,"searchable":false,"label":"short","reduce":function (language) { return language.title; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$twemoji.parse(option.icon))}})]}}]),model:{value:(_vm.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$i18n, "locale", $$v)},expression:"$i18n.locale"}})],1),(
        this.$store.getters.isLoggedIn &&
        this.$store.getters.userType == 'travelAssistEmployee'
      )?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideNotifications),expression:"hideNotifications"}],staticClass:"nav-bar-notification"},[_c('p',{on:{"click":_vm.openNotifications}},[_c('span',{staticClass:"bell-img",domProps:{"innerHTML":_vm._s(this.$twemoji.parse('🔔'))}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.newNotifications),expression:"newNotifications"}],staticClass:"new-notification-marker"},[_vm._v("oo")])]),(_vm.showNotifications)?_c('div',{staticClass:"notification-box-display"},[_c('div',{staticClass:"notification-display-container"},_vm._l((_vm.notifications),function(notification,notificationIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.displayNotificationInfo[notificationIndex].message != '' &&
              _vm.displayNotificationInfo[notificationIndex].length > 0
            ),expression:"\n              displayNotificationInfo[notificationIndex].message != '' &&\n              displayNotificationInfo[notificationIndex].length > 0\n            "}],key:notification.id,staticClass:"notification-display"},[_c('div',{staticClass:"notification-text-container",style:(notification.seen
                  ? { backgroundColor: 'white' }
                  : { backgroundColor: 'lightblue' })},[_c('span',{staticClass:"notification-message-display"},[_vm._v(" "+_vm._s(notification.message)+" ")]),(
                  _vm.displayNotificationInfo[notificationIndex].constructor ===
                  Array
                )?_c('div',{staticClass:"notification-info-display"},[_c('ul',_vm._l((_vm.displayNotificationInfo[
                      notificationIndex
                    ]),function(element){return _c('li',{key:element.id},[_c('router-link',{staticClass:"notification-link",attrs:{"to":element.to,"target":"_blank"}},[_vm._v(_vm._s(element.message))])],1)}),0)]):_c('div',{staticClass:"notification-info-display"},[_c('router-link',{staticClass:"notification-link",style:({ paddingLeft: '20px' }),attrs:{"to":_vm.displayNotificationInfo[notificationIndex].to}},[_vm._v(" "+_vm._s(_vm.displayNotificationInfo[notificationIndex].message)+" ")])],1),_c('span',{staticClass:"notification-date-display"},[_vm._v(_vm._s(new Date(notification.date).toDateString()))]),_c('br')]),_c('hr')])}),0),_c('button',{staticClass:"notification-load-button",attrs:{"type":"button"},on:{"click":_vm.updateNotifications}},[_vm._v(" 🔁 ")])]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideUserMenu),expression:"hideUserMenu"}],staticClass:"personnal-space-container"},[(_vm.checkWindowSize)?_c('button',{staticClass:"personnal-space-button",style:({
          color: _vm.$store.getters.color.fontColor,
          backgroundColor: _vm.$store.getters.color.color2,
        }),attrs:{"type":"button"},on:{"click":function($event){return _vm.personnalSpaceFunc()}}},[(this.$store.getters.isLoggedIn && this.$store.getters.userType == 'client')?_c('img',{attrs:{"src":require("../assets/default-avatar.png"),"height":"30px"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.personnalSpace)+" ")]):_c('img',{attrs:{"src":require("../assets/menu_white.png"),"alt":"Menu"},on:{"click":function($event){return _vm.personnalSpaceFunc()}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUserMenu),expression:"showUserMenu"}],staticClass:"user-menu",style:({
          backgroundColor: _vm.$store.getters.color.color3,
          borderColor: _vm.$store.getters.color.color2,
        })},_vm._l((_vm.sections),function(section,sectionIndex){return _c('div',{key:section.id,staticClass:"user-menu-section"},[_c('p',{style:({ backgroundColor: _vm.$store.getters.color.color1 }),on:{"click":function($event){return _vm.goToNav(section.route)}}},[_vm._v(" "+_vm._s(section.name)+" ")]),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(sectionIndex != _vm.sections.length - 1),expression:"sectionIndex != sections.length - 1"}],style:({ color: _vm.$store.getters.color.color2 })})])}),0)])]),(_vm.informationDisplay.show == true && _vm.informationDisplay.message != '')?_c('div',{class:_vm.informationDisplay.class},[_c('span',[_vm._v(_vm._s(_vm.informationDisplay.message))]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){_vm.informationDisplay.show = false}}},[_vm._v("X")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }