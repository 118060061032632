import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import VueClipboard from "vue-clipboard2";
import Autocomplete from "v-autocomplete";
import routes from "./routes";
import Axios from "axios";
import store from "./store";
import messages from "./messages";
import dateTimeFormats from "./dateTimeFormats";
import Vuetwemoji from "vue-twemoji";
import vSelect from "vue-select";
import VModal from 'vue-js-modal'
import "vue-select/dist/vue-select.css";

Vue.use(VueRouter);
Vue.use(Autocomplete);
Vue.use(VueI18n);
Vue.use(VueClipboard);
Vue.use(Vuetwemoji);
Vue.use(VModal)
Vue.component("v-select", vSelect);
Vue.prototype.$store = store;
Vue.prototype.$http = Axios;

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    store
      .dispatch("tokenLogin", to.query.token)
      .then(() => {
        next();
      })
      .catch((err) => {
        this.$store.dispatch("push_error_message", err.response.data);
      });
  } else {
    next();
  }
});

const i18n = new VueI18n({
  locale: navigator.language || navigator.userLanguage || "fr-FR",
  fallbackLocale: "en-US",
  messages,
  dateTimeFormats,
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  i18n,
}).$mount("#app");
