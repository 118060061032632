<template>
  <div
    class="navbar-container"
    :style="{
      color: $store.getters.color.fontColor,
      backgroundColor: $store.getters.color.color1,
    }"
  >
    <router-link to="/" class="home-title">
      <img
        :src="$store.getters.agencyPic"
        class="agency-pic"
        height="70"
        width="70"
        v-if="$store.getters.agencyPic"
      />
      <span class="navbar-title">
        {{ $store.getters.tourAgencyName.replace(/&amp;/g, "&") }}
      </span>
    </router-link>
    <div class="navbar-right-display">
      <div class="language-selection">
        <v-select
          :options="options"
          :clearable="false"
          :searchable="false"
          label="short"
          :reduce="(language) => language.title"
          v-model="$i18n.locale"
          class="language-chooser"
        >
          <template slot="option" slot-scope="option">
            <span v-html="$twemoji.parse(option.icon)"></span>
          </template>
        </v-select>
      </div>
      <div
        class="nav-bar-notification"
        v-if="
          this.$store.getters.isLoggedIn &&
          this.$store.getters.userType == 'travelAssistEmployee'
        "
        v-click-outside="hideNotifications"
      >
        <p @click="openNotifications">
          <span v-html="this.$twemoji.parse('🔔')" class="bell-img"></span>
          <span class="new-notification-marker" v-show="newNotifications"
            >oo</span
          >
        </p>
        <div class="notification-box-display" v-if="showNotifications">
          <div class="notification-display-container">
            <div
              class="notification-display"
              v-for="(notification, notificationIndex) in notifications"
              :key="notification.id"
              v-show="
                displayNotificationInfo[notificationIndex].message != '' &&
                displayNotificationInfo[notificationIndex].length > 0
              "
            >
              <div
                class="notification-text-container"
                :style="
                  notification.seen
                    ? { backgroundColor: 'white' }
                    : { backgroundColor: 'lightblue' }
                "
              >
                <span class="notification-message-display">
                  {{ notification.message }}
                </span>
                <div
                  class="notification-info-display"
                  v-if="
                    displayNotificationInfo[notificationIndex].constructor ===
                    Array
                  "
                >
                  <ul>
                    <li
                      v-for="element in displayNotificationInfo[
                        notificationIndex
                      ]"
                      :key="element.id"
                    >
                      <router-link
                        :to="element.to"
                        target="_blank"
                        class="notification-link"
                        >{{ element.message }}</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div class="notification-info-display" v-else>
                  <router-link
                    class="notification-link"
                    :to="displayNotificationInfo[notificationIndex].to"
                    :style="{ paddingLeft: '20px' }"
                  >
                    {{ displayNotificationInfo[notificationIndex].message }}
                  </router-link>
                </div>
                <span class="notification-date-display">{{
                  new Date(notification.date).toDateString()
                }}</span>
                <br />
              </div>
              <hr />
            </div>
          </div>
          <button
            type="button"
            class="notification-load-button"
            @click="updateNotifications"
          >
            🔁
          </button>
        </div>
      </div>
      <div class="personnal-space-container" v-click-outside="hideUserMenu">
        <button
          type="button"
          @click="personnalSpaceFunc()"
          class="personnal-space-button"
          :style="{
            color: $store.getters.color.fontColor,
            backgroundColor: $store.getters.color.color2,
          }"
          v-if="checkWindowSize"
        >
        <img src="../assets/default-avatar.png" height="30px" v-if="this.$store.getters.isLoggedIn && this.$store.getters.userType == 'client'">
          {{ personnalSpace }}
        </button>
        <img
          src="../assets/menu_white.png"
          alt="Menu"
          @click="personnalSpaceFunc()"
          v-else
        />
        <div
          class="user-menu"
          v-show="showUserMenu"
          :style="{
            backgroundColor: $store.getters.color.color3,
            borderColor: $store.getters.color.color2,
          }"
        >
          <div
            class="user-menu-section"
            v-for="(section, sectionIndex) in sections"
            :key="section.id"
          >
            <p
              @click="goToNav(section.route)"
              :style="{ backgroundColor: $store.getters.color.color1 }"
            >
              {{ section.name }}
            </p>
            <hr
              v-show="sectionIndex != sections.length - 1"
              :style="{ color: $store.getters.color.color2 }"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      :class="informationDisplay.class"
      v-if="informationDisplay.show == true && informationDisplay.message != ''"
    >
      <span>{{ informationDisplay.message }}</span>
      <button type="button" @click="informationDisplay.show = false">X</button>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "NavBar",
  data() {
    return {
      showUserMenu: false,
      showNotifications: false,
      notifications: [],
      name: "",
      testValue: "",
      options: [
        { title: "fr-FR", icon: "🇫🇷", short: "FR" },
        { title: "en-US", icon: "🇺🇸", short: "US" },
        { title: "en-GB", icon: "🇬🇧", short: "UK" },
      ],
      informationDisplay: {
        message: "",
        class: "error-message",
        show: true,
      },
    };
  },
  computed: {
    checkWindowSize: function () {
      return window.screen.width > 600;
    },
    personnalSpace: function () {
      if (this.$store.getters.isLoggedIn) {
        return this.$t("navBar.greetings", { name: this.name });
      } else {
        return this.$t("navBar.signIn");
      }
    },
    newNotifications: function () {
      for (let i = 0; i < this.notifications.length; i++) {
        if (!this.notifications[i].seen) {
          return true;
        }
      }
      return false;
    },
    sections: function () {
      if (this.$store.getters.isLoggedIn) {
        switch (this.$store.getters.userType) {
          case "client":
            return [
              {
                name: this.$t("navBar.profile"),
                route: "/client/" + this.$store.getters.userId,
              },
//              { name: this.$t("navBar.messages"), route: "/messages" },
//              { name: this.$t("navBar.support"), route: "/supporttchat" },
              { name: this.$t("navBar.signOut"), route: "/logout" },
            ];
          case "travelAssistEmployee":
            return [
              { name: "DashBoard", route: "/search" },
              { name: "Database", route: "/database" },
              { name: "Documents", route: "/documents" },
              {
                name: this.$t("navBar.profile"),
                route: "/travelassist/" + this.$store.getters.userId,
              },
              { name: "New Partner", route: "/create/employee" },
              { name: "New TravelAssister", route: "/create/travelassist" },
              { name: this.$t("navBar.signOut"), route: "/logout" },
            ];
          case "tourAgencyEmployee":
            return [
              {
                name: this.$t("navBar.profile"),
                route: "/employee/" + this.$store.getters.userId,
              },
              {
                name: this.$t("navBar.agency"),
                route: "/agency/" + this.$store.getters.tourAgencyId,
              },
              { name: this.$t("navBar.signOut"), route: "/logout" },
            ];
          default:
            return [
              { name: "Tickets", route: "/ticket" },
              { name: "Search Travels and Tickets", route: "/searchAgent" },
              { name: this.$t("navBar.signOut"), route: "/logout" },
            ];
        }
      } else {
        return [];
      }
    },
    displayNotificationInfo: function () {
      let res = [];
      this.notifications.forEach((notification) => {
        if (notification.information) {
          switch (notification.message) {
            case "A new travel has been created":
              {
                res.push({
                  to: "/travel/" + notification.information.travelId,
                  message: "See Travel",
                });
              }
              break;
            case "A new Ticket has been opened":
              {
                res.push({
                  to: "/ticket/" + notification.information.ticketId,
                  message: "See Ticket",
                });
              }
              break;
            case "A ticket is opened and still unhandled":
              {
                res.push({
                  to: "/ticket/" + notification.information.ticketId,
                  message: "See Ticket",
                });
              }
              break;
            case "These travels starts today":
              {
                let temp = [];
                notification.information.travelList.forEach((element) => {
                  temp.push({
                    to: "/travel/" + element._id,
                    message: element.name,
                  });
                });
                res.push(temp);
              }
              break;
            case "These travels starts tomorrow":
              {
                let temp = [];
                notification.information.travelList.forEach((element) => {
                  temp.push({
                    to: "/travel/" + element._id,
                    message: element.name,
                  });
                });
                res.push(temp);
              }
              break;
            case "A new PreAgency has been created":
              {
                res.push({
                  to: "/preagency/validation/" + notification.information.preagencyId,
                  message: "See PreAgency",
                });
              }
              break;
            default:
              res.push({ to: "", name: "" });
          }
        } else {
          res.push({ to: "", message: "" });
        }
      });
      return res;
    },
  },
  methods: {
    personnalSpaceFunc() {
      if (this.$store.getters.isLoggedIn) {
        this.showUserMenu = !this.showUserMenu;
      } else {
        this.$router.push("/login");
      }
    },
    hideUserMenu() {
      this.showUserMenu = false;
    },
    hideNotifications() {
      this.showNotifications = false;
    },
    openNotifications() {
      this.showNotifications = !this.showNotifications;
      if (this.showNotifications) {
        setTimeout(() => {
          for (let i = 0; i < this.notifications.length; i++) {
            this.notifications[i].seen = true;
          }
          HandleApi.markNotificationRead(this.$store.getters.userId);
        }, 2000);
      }
    },
    goToNav(route) {
      this.$router.push(route);
      this.showUserMenu = false;
    },
    async updateNotifications() {
      if (this.$store.getters.isLoggedIn) {
        let newValue = this.$store.getters.userId;
        if (this.$store.getters.userType == "contactCenterAgent") {
          HandleApi.getAgentInformation(newValue)
            .then((res) => {
              this.name = res.username;
            })
            .catch(() => {
              this.$store.dispatch("logout");
              this.$router.push("/");
            });
        } else {
          if (this.$store.getters.userType == "tourAgencyEmployee") {
            HandleApi.getEmployeeInformation(newValue)
              .then((res) => {
                this.name = res.employee.name;
              })
              .catch(() => {
                this.$store.dispatch("logout");
                this.$router.push("/");
              });
          } else {
            if (this.$store.getters.userType == "client") {
              HandleApi.getClientInformation(newValue)
                .then((res) => {
                  this.name = res.client.name;
                })
                .catch(() => {
                  this.$store.dispatch("logout");
                  this.$router.push("/");
                });
            } else {
              if (this.$store.getters.userType == "travelAssistEmployee") {
                HandleApi.getTravelAssistInformation(newValue)
                  .then((res) => {
                    this.name = res.name;
                    this.notifications = res.notification.sort(function (a, b) {
                      return new Date(a.date) - new Date(b.date);
                    });
                    this.notifications.reverse();
                  })
                  .catch(() => {
                    this.$store.dispatch("logout");
                    this.$router.push("/");
                  });
              }
            }
          }
        }
      }
    },
  },
  async created() {
    this.updateNotifications();
    this.$store.watch(
      (state, getters) => getters.userId,
      async (newValue) => {
        if (this.$store.getters.isLoggedIn) {
          if (this.$store.getters.userType == "contactCenterAgent") {
            HandleApi.getAgentInformation(newValue)
              .then((res) => {
                this.name = res.username;
              })
              .catch(() => {
                this.$store.dispatch("logout");
                this.$router.push("/");
              });
          } else {
            if (this.$store.getters.userType == "tourAgencyEmployee") {
              HandleApi.getEmployeeInformation(newValue)
                .then((res) => {
                  this.name = res.employee.surname;
                })
                .catch(() => {
                  this.$store.dispatch("logout");
                  this.$router.push("/");
                });
            } else {
              if (this.$store.getters.userType == "client") {
                HandleApi.getClientInformation(newValue)
                  .then((res) => {
                    this.name = res.client.surname;
                  })
                  .catch(() => {
                    this.$store.dispatch("logout");
                    this.$router.push("/");
                  });
              } else {
                if (this.$store.getters.userType == "travelAssistEmployee") {
                  HandleApi.getTravelAssistInformation(newValue)
                    .then((res) => {
                      this.name = res.surname;
                      this.notifications = res.notification;
                    })
                    .catch(() => {
                      this.$store.dispatch("logout");
                      this.$router.push("/");
                    });
                }
              }
            }
          }
        }
      }
    );
    this.$store.watch(
      (state, getters) => getters.informationMessage.updateNumber,
      async () => {
        let temp = this.$store.getters.informationMessage.updateNumber;
        this.informationDisplay.message = this.$store.getters.informationMessage.message;
        this.informationDisplay.class = "notification-temp-class";
        this.informationDisplay.class = this.$store.getters.informationMessage.type;
        this.informationDisplay.show = true;
        setTimeout(() => {
          if (temp == this.$store.getters.informationMessage.updateNumber) {
            this.informationDisplay.show = false;
          }
        }, 10000);
      }
    );
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          window.console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;

        // add Event Listeners
        document.addEventListener("click", handler);
      },

      unbind: function (el) {
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
};
</script>

<style scoped>
.navbar-container {
  background-color: #00909f;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hide;
}

.navbar-right-display {
  display: flex;
  align-items: center;
}

.home-title {
  text-decoration: none;
  vertical-align: middle;
  float: left;
}

.navbar-container img {
  display: inline-block;
  margin: 10px;
  vertical-align: middle;
}

.navbar-container .navbar-title {
  color: white;
  font-size: 35px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
}

@media only screen and (max-width: 800px) {
  .navbar-container .navbar-title {
    display: none;
  }
}
.nav-bar-notification {
  margin: 20px;
}

.nav-bar-notification p {
  margin: 0;
  border: 3px solid rgba(0, 0, 0, 0);
  padding: 5px;
  border-radius: 10px;
}

.nav-bar-notification p:hover {
  margin: 0;
  border-color: #034c57;
  cursor: pointer;
}

.personnal-space-button {
  background-color: #9ad3d1;
  color: white;
  border: none;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  align-self: center;
  margin-right: 20px;
}

@media only screen and (max-width: 500px) {
  .personnal-space-button {
    margin-right: 0;
  }
}

.personnal-space-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.personnal-space-button > img {
  margin: 0 5px;
  border-radius: 50%;
}

.user-menu {
  background-color: #034c57;
  color: white;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 70px;
  width: 250px;
  border: 3px solid #9ad3d1;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 5px 5px 5px lightgrey;
  z-index: 100;
}

.user-menu hr {
  color: #9ad3d1;
}

.user-menu p {
  background-color: #00909f;
  border-radius: 40px;
  padding: 5px 0;
}

.user-menu p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.notification-box-display {
  position: absolute;
  right: 20px;
  top: 70px;
  width: 500px;
  height: 500px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
}

.notification-display-container {
  overflow-y: scroll;
  height: calc(100% - 30px);
}

.notification-box-display button {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid rgba(0, 0, 0, 0);
  margin-top: 5px;
  border-radius: 10px;
  padding: 5px;
}

.notification-box-display button:hover {
  border-color: lightgrey;
}

.notification-display-container hr {
  margin-top: 5px;
  color: #034c57;
}

.notification-text-container {
  padding: 5px;
}

.notification-date-display {
  float: right;
  color: grey;
  margin-bottom: 2px;
}

.new-notification-marker {
  color: red;
  background-color: red;
  border-radius: 10px;
  font-size: 5px;
}

.notification-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #99ffc8;
  color: #6bb378;
  border: 2px solid #6bb378;
  border-radius: 10px;
  min-height: 27px;
  max-width: 50%;
  padding: 10px;
  font-size: 17px;
  margin: 0 auto;
}

.notification-message button {
  margin-left: 20px;
  background-color: #99ffc8;
  color: #6bb378;
  border: 2px solid #99ffc8;
  border-radius: 5px;
  padding: 5px;
}

.notification-message button:hover {
  border-color: #6bb378;
  cursor: pointer;
}

.error-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ecb2b2;
  color: red;
  border: 2px solid red;
  border-radius: 10px;
  min-height: 27px;
  max-width: 50%;
  padding: 10px;
  font-size: 17px;
  margin: 0 auto;
}

.error-message button {
  margin-left: 20px;
  background-color: #ecb2b2;
  color: red;
  border: 2px solid #ecb2b2;
  border-radius: 5px;
  padding: 5px;
}

.error-message button:hover {
  border-color: red;
  cursor: pointer;
}

.notification-temp-class {
  opacity: 1;
}

.notification-message-display {
  color: black;
}

.notification-info-display {
  color: black;
}
</style>

<style>
.notification-link {
  color: black;
  text-decoration: none;
  margin-right: 5px;
}
.notification-link:hover {
  text-decoration: underline;
}

.language-selection {
  margin: 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid white;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 20px;
  line-height: 20px;
}

.language-chooser .vs__dropdown-toggle {
  border: none;
}

.language-chooser .vs__search::placeholder {
  border: none;
}

.language-chooser .vs__selected {
  color: white;
  font-weight: bold;
  font-size: 15px;
}

.language-chooser .vs__dropdown-menu img {
  border: none;
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 500px) {
  .language-chooser .vs__search {
    display: none;
  }

  .language-chooser .vs__actions {
    display: none;
  }
}

.emoji {
  height: 20px;
  width: 20px;
}
</style>
