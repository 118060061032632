<template>
  <div class="result">
    <router-link class="result-title" :to="title.id">{{
      title.text
    }}</router-link>
    <br />
    <span class="display1" v-if="display1.text != ''">{{ display1.text }}</span>
    <br />
    <div class="display23">
      <span class="display2" v-if="display2.text != ''">
        {{ display2.text }}
      </span>
      <router-link
        class="display3"
        v-if="display3.text != ''"
        :to="display3.id"
      >
        {{ display3.text }}
      </router-link>
    </div>
    <div class="tag-container">
      <div
        class="tags"
        v-for="tag in tags"
        :key="tag.id"
        :style="{ backgroundColor: tag.color }"
      >
        {{ tag.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "result",
  props: {
    title: {
      type: Object,
    },
    display1: {
      type: Object,
    },
    display2: {
      type: Object,
    },
    display3: {
      type: Object,
    },
    tags: {
      type: Array,
    },
  },
};
</script>
<style scoped>
.result {
  border: 1px solid rgb(240, 240, 240);
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  min-height: 120px;
  font-size: 12px;
  box-shadow: 2px 2px 2px lightgrey;
}

.result-title {
  margin-top: 8px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  color: black;
  text-decoration: none;
}

.result-title:hover {
  text-decoration: underline;
  cursor: pointer;
}

.display1 {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
}

.display23 {
  height: 12px;
}

.display3 {
  float: right;
  color: black;
  text-decoration: none;
}

.display3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tag-container {
  margin-top: 10px;
  margin-bottom: 5px;
  overflow-wrap: break-word;
}

.tags {
  display: inline-block;
  padding: 3px 10px;
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 12px;
}
</style>
