import Search from "./components/Search.vue";
import SearchAgent from "./components/SearchAgent.vue";
const ClientDisplay = () =>
  import(
    /* webpackChunkName: "clientDisplay" */ "./components/ClientDisplay.vue"
  );
const AgencyDisplay = () =>
  import(
    /* webpackChunkName: "agencyDisplay" */ "./components/AgencyDisplay.vue"
  );
const TravelDisplay = () =>
  import(
    /* webpackChunkName: "travelDisplay" */ "./components/TravelDisplay.vue"
  );
const AgentDisplay = () =>
  import(
    /* webpackChunkName: "agentDisplay" */ "./components/AgentDisplay.vue"
  );
const TravelAssistEmployeeDisplay = () =>
  import(
    /* webpackChunkName: "travelAssistEmployeeDisplay" */ "./components/TravelAssistEmployeeDisplay.vue"
  );
const VoucherDisplay = () =>
  import(
    /* webpackChunkName: "voucherDisplay" */ "./components/VoucherDisplay.vue"
  );
const TourAgencyEmployeeDisplay = () =>
  import(
    /* webpackChunkName: "tourAgencyEmployeeDisplay" */ "./components/TourAgencyEmployeeDisplay.vue"
  );
const TravelAssistForm = () =>
  import(
    /* webpackChunkName: "travelAssistForm" */ "./components/TravelAssistEmployeeForm.vue"
  );
const VoucherForm = () =>
  import(/* webpackChunkName: "voucherForm" */ "./components/VoucherForm.vue");
const TourAgencyEmployeeForm = () =>
  import(
    /* webpackChunkName: "tourAgencyEmployeeForm" */ "./components/TourAgencyEmployeeForm.vue"
  );
const TravelForm = () =>
  import(/* webpackChunkName: "travelForm" */ "./components/TravelForm.vue");
const ClientForm = () =>
  import(/* webpackChunkName: "clientForm" */ "./components/ClientForm.vue");
import LoginForm from "./components/LoginForm.vue";
import store from "./store";
const Component401 = () =>
  import(/* webpackChunkName: "401" */ "./components/401.vue");
const Component403 = () =>
  import(/* webpackChunkName: "403" */ "./components/403.vue");
const ChangePassword = () =>
  import(
    /* webpackChunkName: "changePassword" */ "./components/ChangePassword.vue"
  );
const Messages = () =>
  import(/* webpackChunkName: "messages" */ "./components/Message.vue");
const Ticket = () =>
  import(/* webpackChunkName: "ticket" */ "./components/Ticket.vue");
const ResetPwd = () =>
  import(/* webpackChunkName: "resetPwd" */ "./components/NewPasswordForm.vue");
const Database = () =>
  import(/* webpackChunkName: "database" */ "./components/Database.vue");
const TicketDisplay = () =>
  import(
    /* webpackChunkName: "ticketDisplay" */ "./components/TicketDisplay.vue"
  );
const TravelerProfileDisplay = () =>
  import(
    /* webpackChunkName: "travelerProfileDisplay" */ "./components/TravelerProfileDisplay"
  );
const TravelerProfileForm = () =>
  import(
    /* webpackChunkName: "travelerProfileForm" */ "./components/TravelerProfileForm"
  );
const TravelTchat = () =>
  import(/* webpackChunkName: "travelChat" */ "./components/TravelTchat");
const Checkout = () =>
  import(/* webpackChunkName: "checkout" */ "./components/Checkout.vue");
const AffiliationForm = () =>
  import(
    /* webpackChunkName: "affiliationForm" */ "./components/AffiliationForm.vue"
  );
const AffiliationDisplay = () =>
  import(
    /* webpackChunkName: "affiliationDisplay" */ "./components/AffiliationDisplay.vue"
  );
const ChangeEmail = () =>
  import(/* webpackChunkName: "changeEmail" */ "./components/ChangeEmail.vue");
const PasswordReset = () =>
  import(
    /* webpackChunkName: "passwordReset" */ "./components/PasswordReset.vue"
  );
import HandleApi from "./apiHandle.js";
const DocumentEditor = () =>
  import(
    /* webpackChunkName: "documentEditor" */ "./components/DocumentEditor"
  );
const DocumentList = () =>
  import(/* webpackChunkName: "documentList" */ "./components/DocumentList");
const SupportTchat = () =>
  import(/* webpackChunkName: "supportChat" */ "./components/SupportTchat.vue");
const PaymentSetup = () =>
  import(
    /* webpackChunkName: "paymentSetup" */ "./components/PaymentSetup.vue"
  );
const PreAgencyValidation = () =>
  import(
    /* webpackChunkName: "preAgencyValidation" */ "./components/PreAgencyValidation.vue"
  );
const PreAgencyForm = () =>
  import(
    /* webpackChunkName: "preAgencyForm" */ "./components/PreAgencyForm.vue"
  );
const AgencyContract = () =>
  import(
    /* webpackChunkName: "agencyContract" */ "./components/AgencyContract.vue"
  );
const MetricsDisplay = () =>
  import(
    /* webpackChunkName: "metricsDisplay" */ "./components/MetricsDisplay.vue"
  );

// Config const
const backendURL = "https://apidev.mytravelassistant.io/";

const routes = [
  {
    path: "/index.html",
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next("/login");
      } else {
        switch (store.getters.userType) {
          case "client":
            next("/client/" + store.getters.userId);
            break;
          case "tourAgencyEmployee":
            next("/agency/" + store.getters.tourAgencyId);
            break;
          case "contactCenterAgent":
            next("/ticket");
            break;
          case "travelAssistEmployee":
            next("/search");
            break;
        }
      }
    },
  },
  {
    path: "/",
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next("/login");
      } else {
        switch (store.getters.userType) {
          case "client":
            next("/client/" + store.getters.userId);
            break;
          case "tourAgencyEmployee":
            next("/agency/" + store.getters.tourAgencyId);
            break;
          case "contactCenterAgent":
            const agent = await HandleApi.getAgent("/" + store.getters.userId);
            console.log(agent);
            if (agent.isSupervisor) {
              next("/searchAgent");
            } else {
              next("/ticket");
            }
            break;
          case "travelAssistEmployee":
            next("/search");
            break;
        }
      }
    },
  },
  {
    path: "/messages",
    component: Messages,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "client") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/messages");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },

  {
    path: "/supporttchat",
    component: SupportTchat,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "client") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/supporttchat");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/ticket",
    component: Ticket,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "contactCenterAgent") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/ticket");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/ticket/:id",
    component: TicketDisplay,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.userType == "travelAssistEmployee" ||
        store.getters.userType == "contactCenterAgent"
      ) {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/ticket/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/search",
    component: Search,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/search");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/searchAgent",
    component: SearchAgent,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "contactCenterAgent") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/searchAgent");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/database",
    component: Database,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.userType == "contactCenterAgent" ||
        store.getters.userType == "travelAssistEmployee"
      ) {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/database");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/joinTravel/:id",
    beforeEnter: (to, from, next) => {
      store.dispatch("setJoinTravel", {
        travel_password: to.query.password,
        travel_id: to.params.id,
      });
      if (!store.getters.isLoggedIn) {
        const nextUrl = encodeURIComponent("/database/" + to.params.id);
        next("/login?nextUrl=" + nextUrl);
      } else {
        next({
          path: "/travel/" + to.params.id,
        });
      }
    },
  },
  {
    path: "/client/:id",
    component: ClientDisplay,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.userType == "travelAssistEmployee" ||
        store.getters.userType == "tourAgencyEmployee" ||
        store.getters.userType == "contactCenterAgent" ||
        store.getters.userId == to.params.id
      ) {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/client/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/agency/:id",
    component: AgencyDisplay,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType != "client") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/agency/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/travel/:id",
    component: TravelDisplay,
  },
  {
    path: "/agent/:id",
    component: AgentDisplay,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/agent/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/travelassist/:id",
    component: TravelAssistEmployeeDisplay,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/travelassist/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/voucher/:id",
    component: VoucherDisplay,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/voucher/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/employee/:id",
    component: TourAgencyEmployeeDisplay,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.userType == "travelAssistEmployee" ||
        (store.getters.userType == "tourAgencyEmployee" &&
          store.getters.userId == to.params.id)
      ) {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/employee/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/profile/:id",
    component: TravelerProfileDisplay,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        const nextUrl = encodeURIComponent("/profile/" + to.params.id);
        next("/login?nextUrl=" + nextUrl);
      } else {
        next();
      }
    },
  },
  {
    path: "/create/travelassist",
    component: TravelAssistForm,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/create/travelassist");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/create/voucher",
    component: VoucherForm,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/create/voucher");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/create/employee",
    component: TourAgencyEmployeeForm,
    props: true,
    name: "employeeForm",
    beforeEnter: (to, from, next) => {
      if (
        store.getters.userType == "travelAssistEmployee" ||
        store.getters.userType == "tourAgencyEmployee"
      ) {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/create/employee");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/create/travel",
    component: TravelForm,
    name: "travelForm",
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        const nextUrl = encodeURIComponent("/create/travel");
        next("/login?nextUrl=" + nextUrl);
      } else {
        next();
      }
    },
  },
  {
    path: "/create/client",
    component: ClientForm,
    name: "clientForm",
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn && store.getters.userType == "client") {
        next("/client/" + store.getters.userId);
      } else {
        next();
      }
    },
  },
  {
    path: "/create/profile",
    component: TravelerProfileForm,
    name: "profileForm",
    props: true,
    beforeEnter: (to, from, next) => {
      if (
        !store.getters.userType == "client" ||
        !store.getters.userType == "tourAgencyEmployee"
      ) {
        next("/401");
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/create/profile");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/logout",
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        store.dispatch("logout").then(next("/"));
      } else {
        next("/");
      }
    },
  },
  { path: "/login", component: LoginForm },
  { path: "/requestresetpwd", component: ResetPwd },
  { path: "/resetpassword", component: PasswordReset },
  { path: "/401", component: Component401 },
  { path: "/403", component: Component403 },
  {
    path: "/changepassword",
    component: ChangePassword,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        const nextUrl = encodeURIComponent("/changepassword");
        next("/login?nextUrl=" + nextUrl);
      } else if (store.getters.userType == "contactCenterAgent") {
        next("/401");
      } else {
        next();
      }
    },
  },
  {
    path: "/changeemail",
    component: ChangeEmail,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        const nextUrl = encodeURIComponent("/changeemail");
        next("/login?nextUrl=" + nextUrl);
      } else if (store.getters.userType == "contactCenterAgent") {
        next("/401");
      } else {
        next();
      }
    },
  },
  {
    path: "/telegramhelp",
    name: "telegramhelp",
    beforeEnter() {
      location.href =
        "https://bigone.zendesk.com/hc/en-us/articles/360008014894-How-to-get-the-Telegram-user-ID-";
    },
  },
  {
    path: "/traveltchat/:id",
    component: TravelTchat,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.userType === "travelAssistEmployee" ||
        store.getters.userType === "tourAgencyEmployee" ||
        store.getters.userType === "contactCenterAgent"
      ) {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/traveltchat/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/checkout",
    component: Checkout,
    props: true,
    name: "checkout",
  },
  {
    path: "/payment/setup/:id",
    component: PaymentSetup,
    name: "paymentSetup",
  },
  {
    path: "/affiliation/submit/:link",
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "client" || !store.getters.isLoggedIn) {
        store.dispatch("add_affiliation", to.params.link);
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent(
            "/affiliation/submit/" + to.params.link
          );
          next("/login?nextUrl=" + nextUrl);
        } else {
          switch (store.getters.userType) {
            case "client":
              next("/client/" + store.getters.userId);
              break;
            case "tourAgencyEmployee":
              next("/employee/" + store.getters.userId);
              break;
            case "contactCenterAgent":
              next("/ticket");
              break;
            case "travelAssistEmployee":
              next("/search");
              break;
          }
        }
      } else {
        next("/401");
      }
    },
  },
  {
    path: "/create/affiliation",
    component: AffiliationForm,
    props: true,
    name: "affiliationForm",
    beforeEnter: (to, from, next) => {
      if (
        store.getters.userType == "travelAssistEmployee" ||
        store.getters.userType == "tourAgencyEmployee"
      ) {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/create/affiliation");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/affiliation/:id",
    name: "affiliationDisplay",
    component: AffiliationDisplay,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.userType == "travelAssistEmployee" ||
        store.getters.userType == "tourAgencyEmployee"
      ) {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/affiliation/" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/employee/remove/:id",
    beforeEnter: async (to, from, next) => {
      let confirm = window.confirm(
        "This action is irreversible, are you sure you want to continue?"
      );
      if (confirm) {
        try {
          let data = {
            employeeId: to.params.id,
          };
          await HandleApi.deleteEmployee(from.params.id, data);
        } catch (err) {
          store.dispatch("push_error_message", err.response.data);
        }
      }
      next(from.path);
      window.location.reload(true);
    },
  },
  {
    path: "/document/editor",
    name: "new-document",
    component: DocumentEditor,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/document/editor");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/document/editor/:id",
    name: "edit-document",
    component: DocumentEditor,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/document/editor" + to.params.id);
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/documents",
    component: DocumentList,
    name: "list-document",
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent("/documents");
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/preagency",
    component: PreAgencyForm,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/preagency/:id",
    component: PreAgencyForm,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "tourAgencyEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          next();
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/preagency/validation/:id",
    component: PreAgencyValidation,
    beforeEnter: (to, from, next) => {
      if (store.getters.userType == "travelAssistEmployee") {
        next();
      } else {
        if (!store.getters.isLoggedIn) {
          const nextUrl = encodeURIComponent(
            "/preagency/validation/" + to.params.id
          );
          next("/login?nextUrl=" + nextUrl);
        } else {
          next("/401");
        }
      }
    },
  },
  {
    path: "/contract/:id",
    component: AgencyContract,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next();
      }
    },
  },
  {
    path: "/metrics",
    component: MetricsDisplay,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        const nextUrl = encodeURIComponent("/metrics/");
        next("/login?nextUrl=" + nextUrl);
      } else if (
        store.getters.userType == "travelAssistEmployee" ||
        store.getters.userType == "contactCenterAgent"
      ) {
        next();
      } else {
        next("/401");
      }
    },
  },
  {
    path: "/invoice/:id/:lang",
    name: "invoiceDownload",
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        const nextUrl = encodeURIComponent(
          "/invoice/" + to.params.id + "/" + to.params.lang
        );
        next("/login?nextUrl=" + nextUrl + "&download=true");
      } else if (
        store.getters.userType == "tourAgencyEmployee" ||
        store.getters.userType == "travelAssistEmployee" ||
        store.getters.userType == "client"
      ) {
        location.href =
          backendURL +
          "download/invoice/" +
          to.params.id +
          "?lang=" +
          to.params.lang +
          "&auth=" +
          store.state.token;
      } else {
        next("/401");
      }
    },
  },
  {
    path: "/download/contract/:id",
    name: "contractDownload",
    beforeEnter: (to) => {
      location.href =
        backendURL +
        "download/contract/" +
        to.params.id +
        "?pass=" +
        to.query.pass;
    },
  },
];

export default routes;
