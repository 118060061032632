<template>
  <div>
    <nav-bar></nav-bar>
    <div class="loader-container" v-show="this.$store.getters.loading">
      <p class="loading-message">{{ this.$store.getters.loadingMessage }}</p>
      <div class="moving-loader-container">
        <div
          class="loader"
          :style="{
            border: '16px solid ' + $store.getters.color.color1,
            borderTop: '16px solid ' + $store.getters.color.color2,
            borderBottom: '16px solid ' + $store.getters.color.color3,
          }"
        ></div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "app",
  components: { NavBar },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });
  },
};
</script>

<style scoped>
.loading-message {
  position: absolute;
  font-weight: bold;
  font-size: 25px;
  top: calc(50% - 180px);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1002;
}

.loader-container {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.moving-loader-container {
  position: absolute;
  top: calc(50% - 60px);
  left: 0;
  right: 0;
  z-index: 1001;
}

.loader {
  border: 16px solid #00909f;
  border-top: 16px solid #034c57;
  border-bottom: 16px solid #9ad3d1;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  z-index: 1002;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
