import axios from "axios";
const token = localStorage.getItem("token");
import TAPic from "./TAPic.js";

const url = "https://apidev.mytravelassistant.io/";
export const bridgeUrl = "https://bridge.travelassist.fr";
export const matrixUrl = "https://matrix.travelassist.fr";
export const matrixHomeserverDomain = "travelassist.fr";
export const azureSasUrl =
  "https://travelassist.blob.core.windows.net/?sv=2021-06-08&ss=b&srt=co&sp=rwdlactx&se=2023-07-08T17:04:41Z&st=2022-07-08T09:04:41Z&sip=0.0.0.0-255.255.255.255&spr=https&sig=0bUrW8WrK%2BxKhwrdnUH8vPu7EIu7U8HqlZZpecIAIko%3D";

if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}

class HandleApi {
  static logout() {
    delete axios.defaults.headers.common["Authorization"];
  }

  static login(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(url + "login", data)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.userId);
          localStorage.setItem("userType", res.data.userType);
          localStorage.setItem("tourAgencyId", res.data.tourAgencyId);
          localStorage.setItem("supportChannel", res.data.supportChannel);
          localStorage.setItem("name", res.data.name);
          if (res.data.color) {
            localStorage.setItem("color1", res.data.color.color1);
            localStorage.setItem("color2", res.data.color.color2);
            localStorage.setItem("color3", res.data.color.color3);
            localStorage.setItem("fontColor", res.data.color.fontColor);
          }
          if (res.data.tourAgencyName) {
            localStorage.setItem("tourAgencyName", res.data.tourAgencyName);
          } else {
            localStorage.setItem("tourAgencyName", "TravelAssist.io");
            localStorage.setItem("agencyPic", TAPic);
            res.data.agencyPic = TAPic;
          }
          if (res.data.agencyPic) {
            localStorage.setItem("agencyPic", res.data.agencyPic);
          }
          axios.defaults.headers.common["Authorization"] = res.data.token;
          resolve(res);
        })
        .catch((err) => {
          localStorage.removeItem("token");
          reject(err);
        });
    });
  }

  static getTokenInformation(token) {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          Authorization: token,
        },
      };
      axios
        .get(url + "login_info", config)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.userId);
          localStorage.setItem("userType", res.data.userType);
          localStorage.setItem("tourAgencyId", res.data.tourAgencyId);
          localStorage.setItem("supportChannel", res.data.supportChannel);
          if (res.data.color) {
            localStorage.setItem("color1", res.data.color.color1);
            localStorage.setItem("color2", res.data.color.color2);
            localStorage.setItem("color3", res.data.color.color3);
            localStorage.setItem("fontColor", res.data.color.fontColor);
          }
          if (res.data.tourAgencyName) {
            localStorage.setItem("tourAgencyName", res.data.tourAgencyName);
          } else {
            localStorage.setItem("tourAgencyName", "TravelAssist.io");
          }
          if (res.data.agencyPic) {
            localStorage.setItem("agencyPic", res.data.agencyPic);
          }
          axios.defaults.headers.common["Authorization"] = res.data.token;
          resolve(res);
        })
        .catch((err) => {
          localStorage.removeItem("token");
          reject(err);
        });
    });
  }

  static resetPwd(data) {
    return axios.post(url + "reset_pw", data);
  }

  static getMessageAuth(id) {
    return axios.get(url + "matrix/clienttoken/" + id).then((res) => res.data);
  }

  static checkTravelPassword(id, data) {
    return axios
      .post(url + "travel/checkpassword/" + id, data)
      .then((res) => res.data);
  }

  static inviteToTravel(id, data) {
    return axios
      .post(url + "travel/invite/" + id, data)
      .then((res) => res.data);
  }

  static getClients(query) {
    return axios.get(url + "client" + query).then((res) => res.data);
  }

  static getTravel(query) {
    return axios.get(url + "travel" + query).then((res) => res.data);
  }

  static getAgency(query) {
    return axios.get(url + "agency" + query).then((res) => res.data);
  }

  static getVoucher(query) {
    return axios.get(url + "voucher" + query).then((res) => res.data);
  }

  static getAgent(query) {
    return axios.get(url + "agent" + query).then((res) => res.data);
  }

  static getEmployee(query) {
    return axios.get(url + "employee" + query).then((res) => res.data);
  }

  static getTravelAssist(query) {
    return axios.get(url + "travelassist" + query).then((res) => res.data);
  }

  static getTicket(query) {
    return axios.get(url + "ticket" + query).then((res) => res.data);
  }

  static getPreAgency() {
    return axios.get(url + "preagency").then((res) => res.data);
  }

  static searchClients(query) {
    return axios.get(url + "client/search" + query).then((res) => res.data);
  }

  static searchTravel(query) {
    return axios.get(url + "travel/search" + query).then((res) => res.data);
  }

  static searchAgency(query) {
    return axios.get(url + "agency/search" + query).then((res) => res.data);
  }

  static searchEmployee(query) {
    return axios.get(url + "employee/search" + query).then((res) => res.data);
  }

  static searchVoucher(query) {
    return axios.get(url + "voucher/search" + query).then((res) => res.data);
  }

  static searchAgent(query) {
    return axios.get(url + "agent/search" + query).then((res) => res.data);
  }

  static searchTravelAssist(query) {
    return axios
      .get(url + "travelassist/search" + query)
      .then((res) => res.data);
  }

  static searchTicket(query) {
    return axios.get(url + "ticket/search" + query).then((res) => res.data);
  }

  static getClientInformation(id) {
    return axios.get(url + "client/" + id).then((res) => res.data);
  }

  static getClientInformationMinified(id) {
    return axios.get(url + "client/minify/" + id).then((res) => res.data);
  }

  static getTravelInformation(id) {
    return axios.get(url + "travel/" + id).then((res) => res.data);
  }

  static getTravelInformationMinified(id) {
    return axios.get(url + "travel/minify/" + id).then((res) => res.data);
  }

  static getAgencyInformation(id) {
    return axios.get(url + "agency/" + id).then((res) => res.data);
  }

  static getAgencyInformationMinified(id) {
    return axios.get(url + "agency/minify/" + id).then((res) => res.data);
  }

  static getEmployeeInformation(id) {
    return axios.get(url + "employee/" + id).then((res) => res.data);
  }

  static getVoucherInformation(id) {
    return axios.get(url + "voucher/" + id).then((res) => res.data);
  }

  static getAgentInformation(id) {
    return axios.get(url + "agent/" + id).then((res) => res.data);
  }

  static getTravelAssistInformation(id) {
    return axios.get(url + "travelassist/" + id).then((res) => res.data);
  }

  static getAffiliationInformation(id) {
    return axios.get(url + "affiliation/" + id).then((res) => res.data);
  }

  static getProfileInformation(id) {
    return axios.get(url + "profile/" + id).then((res) => res.data);
  }

  static getPreAgencyInformation(id) {
    return axios.get(url + "preagency/" + id).then((res) => res.data);
  }

  static putClient(id, data) {
    return axios.put(url + "client/" + id, data);
  }

  static putAgency(id, data) {
    return axios.put(url + "agency/" + id, data);
  }

  static putEmployee(id, data) {
    return axios.put(url + "employee/" + id, data);
  }

  static putTravelAssist(id, data) {
    return axios.put(url + "travelassist/" + id, data);
  }

  static putTravel(id, data) {
    return axios.put(url + "travel/" + id, data);
  }

  static putVoucher(id, data) {
    return axios.put(url + "voucher/" + id, data);
  }

  static putProfile(id, data) {
    return axios.put(url + "profile/" + id, data);
  }

  static putAffiliation(id, data) {
    return axios.put(url + "affiliation/" + id, data);
  }

  static postClient(data) {
    return axios.post(url + "client/", data);
  }

  static postAgencyClient(id, data) {
    return axios.post(url + "client/agency/" + id, data);
  }
  static postAgency(data) {
    return axios.post(url + "agency/", data);
  }

  static postEmployee(data) {
    return axios.post(url + "employee/", data);
  }

  static postTravelAssist(data) {
    return axios.post(url + "travelassist/", data);
  }

  static postTravel(data) {
    return axios.post(url + "travel/", data);
  }

  static postVoucher(data) {
    return axios.post(url + "voucher/", data);
  }

  static postProfile(data) {
    return axios.post(url + "profile/", data);
  }

  static postAffiliation(data) {
    return axios.post(url + "affiliation/", data);
  }

  static postPreAgency(data) {
    return axios.post(url + "preagency", data);
  }

  static requestNextTicket(data) {
    return axios.post(url + "ticket/next/", data).then((res) => res.data);
  }

  static sendEmail(id, data) {
    return axios.post(url + "ticket/email/" + id, data).then((res) => res.data);
  }

  static changeTicketStatus(id, data) {
    return axios.put(url + "ticket/status/" + id, data);
  }

  static updateTicketNotes(id, data) {
    return axios.put(url + "ticket/notes/" + id, data);
  }

  static transferTicketList(data) {
    return axios.put(url + "ticket/transfer", data);
  }

  static getTicketInformation(id) {
    return axios.get(url + "ticket/" + id).then((res) => res.data);
  }

  static putTicket(id, data) {
    return axios.put(url + "ticket/" + id, data);
  }

  static uploadTourismInformation(data) {
    return axios.post(url + "tourismdata/upload", data);
  }

  static searchTourismData(data, keywords) {
    return axios.put(url + "tourismdata/search/" + keywords, data);
  }

  static putTourismData(id, data) {
    return axios.put(url + "tourismdata/" + id, data);
  }

  static deleteTourismData(id) {
    return axios.delete(url + "tourismdata/" + id);
  }

  static addTelegram(id) {
    return axios.post(url + "agency/telegram/" + id).then((res) => res.data);
  }

  static markNotificationRead(id) {
    return axios.put(url + "travelassist/notifications/seen/" + id);
  }

  static uploadRoadmap(id, data) {
    return axios.post(url + "travel/roadmap/" + id, data);
  }

  static getTravelMatrix(id) {
    return axios.get(url + "matrix/traveltoken/" + id).then((res) => res.data);
  }

  static checkAndGetPrice(data) {
    return axios.post(url + "travel/price", data);
  }

  static clientPwd(id, data) {
    return axios.put(url + "client/password/" + id, data);
  }

  static employeePwd(id, data) {
    return axios.put(url + "employee/password/" + id, data);
  }

  static travelassistPwd(id, data) {
    return axios.put(url + "travelassist/password/" + id, data);
  }

  static clientEmail(id, data) {
    return axios.put(url + "client/email/" + id, data);
  }

  static employeeEmail(id, data) {
    return axios.put(url + "employee/email/" + id, data);
  }

  static travelassistEmail(id, data) {
    return axios.put(url + "travelassist/email/" + id, data);
  }

  static confirmTicketHandling(id, data) {
    return axios.post(url + "ticket/confirmHandling/" + id, data);
  }

  static changeResetPassword(data) {
    return axios.put(url + "change_password_reset/", data);
  }

  static deleteEmployee(id, data) {
    return axios.put(url + "agency/employee/" + id, data);
  }

  static getDocumentList() {
    return axios.get(url + "document").then((res) => res.data);
  }

  static getDocument(id) {
    return axios.get(url + "document/" + id).then((res) => res.data);
  }

  static postDocument(data) {
    return axios.post(url + "document/", data);
  }

  static putDocument(id, data) {
    return axios.put(url + "document/" + id, data);
  }

  static deleteDocument(id) {
    return axios.delete(url + "document/" + id);
  }

  static searchDocument(keywords) {
    return axios
      .get(url + "document/search?keywords=" + keywords)
      .then((res) => res.data);
  }

  static checkTelegram(id) {
    return axios.post(url + "agency/check/telegram/" + id);
  }

  static getStripePublishableKey() {
    return axios.get(url + "stripe_key");
  }

  static setupDelayedPayments(id, data) {
    return axios.post(url + "/payment/setup/" + id, data);
  }

  static getProgMessages(id) {
    return axios.get(url + "pm/travel/" + id).then((res) => res.data);
  }

  static putPm(id, data) {
    return axios.put(url + "pm/" + id, data);
  }

  static postPm(data) {
    return axios.post(url + "pm/", data);
  }

  static deletePm(id) {
    return axios.delete(url + "pm/" + id);
  }

  static updateTodo(id, data) {
    return axios.put(url + "travel/todo/" + id, data);
  }

  static deletePreAgency(id) {
    return axios.delete(url + "preagency/" + id);
  }

  static validateContract(id, data) {
    return axios.post(url + "contract/validation/" + id, data);
  }

  static invoiceDownload(id, lang) {
    return axios.get(url + "download/invoice/" + id + "?lang=" + lang);
  }

  static changeTravelDates(id, data) {
    return axios.put(url + "travel/date/" + id, data);
  }

  static putPreAgency(id, data) {
    return axios.put(url + "preagency/" + id, data);
  }
  static getContract(id, mail, pass) {
    return axios.get(
      url + "contract/" + id + "?from=" + mail + "&pass=" + pass
    );
  }
  static contractValidation(id, validation, pass, data) {
    return axios.put(
      url + "contract/" + id + "?validation=" + validation + "&pass=" + pass,
      data
    );
  }
  static getAgencyContract(id, mail, pass) {
    return axios.get(
      url + "agency/contract/" + id + "?from=" + mail + "&pass=" + pass
    );
  }
  static putAgencyContract(id, step, data) {
    return axios.put(url + "agency/contract/" + id + "?step=" + step, data);
  }
  static getHandlingMetrics(query) {
    return axios.get(url + "metrics/handling" + query).then((res) => res.data);
  }

  static getProcessingMetrics(query) {
    return axios
      .get(url + "metrics/processing" + query)
      .then((res) => res.data);
  }

  static getOtherMetrics(query) {
    return axios.get(url + "metrics/other" + query).then((res) => res.data);
  }

  static changeAvatar(id, data) {
    return axios.put(url + "client/avatar/" + id, data);
  }

  static getScheduledMessages(travelId) {
    return axios
      .get(bridgeUrl + "/scheduled", {
        params: {
          roomType: "travel",
          roomId: travelId,
        },
      })
      .then((res) => res.data);
  }

  static postScheduledMessage(travelId, message, timestamp) {
    return axios.post(bridgeUrl + "/scheduled", {
      roomType: "travel",
      roomId: travelId,
      message,
      timestamp,
    });
  }

  static putScheduledMessage(scheduledMessageId, message, timestamp) {
    return axios.put(bridgeUrl + "/scheduled/" + scheduledMessageId, {
      message,
      timestamp,
    });
  }

  static deleteScheduledMessage(scheduledMessageId) {
    return axios.delete(bridgeUrl + "/scheduled/" + scheduledMessageId);
  }

  static getTelegramCount() {
    return axios.get(bridgeUrl + "/telegram/count");
  }
}

export default HandleApi;
