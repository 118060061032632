var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"big-form"},[_c('div',{staticClass:"login-form"},[_c('p',{style:({
        color: _vm.$store.getters.color.fontColor,
        backgroundColor: _vm.$store.getters.color.color1,
      })},[_vm._v(" "+_vm._s(_vm.$t("label.login"))+" ")]),_c('div',{staticClass:"form-container"},[_c('label',{staticClass:"username-label",attrs:{"for":"username"}},[_vm._v(" "+_vm._s(_vm.$t("label.username"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],attrs:{"type":"text","id":"username","required":""},domProps:{"value":(_vm.username)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if (_vm.username && _vm.password) {
            _vm.login();
          }},"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('br'),_c('label',{staticClass:"password-label",attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t("password.password"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","id":"password","required":""},domProps:{"value":(_vm.password)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if (_vm.username && _vm.password) {
            _vm.login();
          }},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('button',{staticClass:"login-button",attrs:{"type":"button","disabled":_vm.checkForm},on:{"click":_vm.login}},[_vm._v(" Go! ")])])]),_c('br'),_c('br'),_c('router-link',{attrs:{"to":"/create/client"}},[_vm._v(_vm._s(_vm.$t("page.registration")))]),_c('router-link',{attrs:{"to":"requestresetpwd"}},[_vm._v(_vm._s(_vm.$t("page.passwordReset")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }