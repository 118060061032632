const messages = {
  "en-US": {
    alert: {
      irreversible: "This action is irreversible, are you sure?",
    },
    error: {
      body401: "You're not allowed to access these data",
      title401: "401 Error",
      body403: "Forbidden Request",
      title403: "403 Error",
      emailCheck: "Email address must match",
      invalidEmail: "Please enter a valid email address",
      underaged: "You must be over 18 to register",
    },
    label: {
      noClient: "You must add at least one traveler to this travel.",
      addedClient: "Clients added to the trip",
      name: "Name",
      surname: "Surname",
      birthdate: "Birthdate",
      email: "Email",
      emailConfirmation: "Email Confirmation",
      phone: "Phone",
      emergencyNumber: "Emergency Number",
      website: "Website",
      language: "Language",
      identifier: "Identifier",
      agency: "Agency",
      travel: "Travels",
      voucher: "Voucher",
      code: "Code",
      gender: "Gender",
      male: "Male",
      female: "Female",
      username: "Username",
      login: "Login",
      date: "Date",
      time: "time",
      agencyName: "Agency Name",
      agencyEmail: "Agency Email (can be the same as Agency director's one)",
      others: "Others",
      other: "Other",
      information: "Information",
      TATchat: "TravelAssist.io tchat",
      geniusTravelTchat: "Genius travel tchat",
      zipCode: "zipCode",
      line: "line {number}",
      browse: "Browse",
      color: "Color {number}",
      fontColor: "Font Color",
      agencyPic: "Select Picture",
      change: "Change",
      create: "Create {object}",
      company: "Company Name",
      vatNumber: "VAT Number",
      progMessages: "Programmed Messages",
      deliveryDate: "Delivery Date",
      informationSaved: "Your information have been saved",
      preAgencyExplanations1:
        "A member of our team will activate your account soon, you'll receive an email with your login information once it will be done. See you soon :)",
      preAgencyExplanations2: "You will redirected to our home page in",
      secondsDot: "seconds...",
      RaCheck:
        "By checking this box you acknowledge having read and agreeing with our",
      tou: "TOU",
      contract: "Partnership contract for tourism companies",
      thanksContract: "We're happy to continue our partnership with you!",
      contractExplanations:
        "You'll be able to find your contract anytime et this url:",
      tac: "Terms and conditions",
      legalNotices: "Legal Notices",
      privacyPolicy: "Privacy Policy",
      mailValidation: "Email validated on ",
      administratorAccount: "Agency administrator account",
      agencyInformation: "Agency information",
      contractInformation: "Information needed to draw up your contract",
      askForOffers: "Make a request to take advantage of promotional offers",
      contractForm: {
        society: "Society name",
        statut: "Statut",
        capital: "Capital",
        address: "Address",
        zip: "Zip Code",
        city: "Society City",
        registerPlace: "Company register place",
        registerId: "Register ID",
        representative: "Representative name",
        country: "Country",
        licence: "Licence Number",
        tvaNumber: "Intra-Community VAT number",
      },
      travelForm: {
        genericInformation: "Generic Information",
        place: "Place",
        thema: "Thema",
        familyVacations: "Family Vacations",
        celebration: "Celebration",
        surprise: "Surprise",
        birthday: "Birthday",
        coupleVacations: "Couple Vacations",
        withFriends: "With Friends",
        music: "Music",
        sport: "Sport",
        discovery: "Discovery",
        startDate: "Start Date",
        endDate: "End Date",
        stopoverStartDate: "Stopover start date",
        stopoverEndDate: "Stopover end date",
        transportation: "Transportation",
        plane: "Plane",
        boat: "Boat",
        car: "Car",
        bus: "Bus",
        train: "Train",
        bike: "Bike",
        walking: "Walking",
        company: "Company",
        number: "Number",
        from: "From",
        to: "To",
        departureDate: "Departure Date",
        departureTime: "Departure Time",
        arrivalDate: "Arrival Date",
        arrivalTime: "Arrival Time",
        roadmap: "See Roadmap",
        uploadRoadmap: "Upload Roadmap",
        messageApp: "Message App",
        accompagnist: "Accompagnist (Press Enter to add)",
        addClientTravel: "Add Client to travel",
        inviteFriends: "Invite you friends to this travel (max: 2)",
        stopover: "Stopover",
        city: "City",
        accomodation: "Accomodation",
        adress: "Address",
        booked: "Booked",
        restaurant: "Restaurant",
        entertainement: "Entertainment",
        transport: "Transport",
        guide: "Guide",
        age: "Age",
        ownApp: "Use your own Agency app for your travel (+9.90€)",
        watchConversation:
          "Watch your client conversation as a ghost user (+3.90€)",
        performance: "Performance",
      },
      travelerProfile: {
        travelerProfile: "My traveler profile",
        comfort: "Comfort",
        simplicity: "Simplicity",
        regular: "Regular Traveler",
        beginning: "It's just the beginning",
        unusualExperiences: "Searching for unusual experiences",
        wellKnown: "Well-known",
        basketball: "See a basketball match",
        unseenPerformance: "Watch an unseen performance",
        discoveries: "Discoveries and visits",
        beachLaze: "Beach and laze",
        culture: "Culture",
        hobbies: "Hobbies",
        sport: "Sport",
        laze: "Laze",
        mostSee: "The most you can see",
        takeTime: "Take your time",
        culinaryExperiences: "Culinary experiences",
        lightHealthy: "Light and healthy food",
        party: "Party all night long",
        earlyBed: "Early in bed to enjoy tomorrow",
        shopping: "Shopping/Souvenirs",
        noShopping: "No shopping during vacations",
        music: "Music",
        licence: "Licence",
        diet: "Diet",
        classical: "Classical",
        jazz: "Jazz",
        rock: "Rock",
        pop: "Pop",
        car: "Car",
        motorbike: "Motorbike",
        boat: "Boat",
        passion: "Passion",
        onePassion:
          "One passion above the other, juste for us to keep it in mind",
        oneSport: "One sport you practice we should know?",
        specificDiet: "Specific diet",
      },
    },
    buttons: {
      save: "Save",
      back: "Back",
      edit: "Edit",
      add: "Add",
      new: "New",
      go: "Go!",
      seeTchat: "See Tchat",
      invite: "Invite!",
      submitVoucher: "Use Voucher",
      delete: "Delete",
      downloadContract: "Download Contract",
      noContract: "Fill contract information",
      validateContract: "Validate contract",
    },
    agency: {
      partnerDate: "Partner since {date}",
      connectApp: "Connect {app}",
      isConnected: "{app} is connected!",
      notConnected: "{app} is not connected",
      checkApp: "Check {app} connection",
      administrator: "Administrator",
      employees: "Employees",
      clients: "Clients",
      travel: "Create a new travel for your clients",
      travels: "Travels",
      employee: "employee",
      deleteEmployee: "Delete employee account",
      invoice: "Invoices",
      noContract: "You haven't sign our partnership contract !",
      waitingValidation:
        "TravelAssist must check your information, you will receive a mail when your contract will be ready.",
    },
    loadMessage: {
      telegramLink: "Generating telegram connection link",
      checkTelegram: "Checking Telegram connection",
      registerEmployee: "Registering Employee",
      createTravel: "Creating Travel",
      clientList: "Retrieving Client List",
      checkout: "Checking your payment",
      deletingEmployee: "Deleting employee",
      saving: "Saving...",
      contractValidated: "Your contract is validated. Creating your agency...",
    },
    password: {
      password: "Password",
      newPassword: "New Password",
      confirmation: "Password Confirmation",
      confirmationMustMatch: "Password and password confirmation must match",
      min8caracters: "Password Must be at least 8 caracters",
      resetPwd: "Reset Password",
      resetPwdInformation:
        "You will be sent a new password by email you'll be able to connect with it",
      currentPassword: "Current Password",
    },
    email: {
      newEmail: "Change email address",
    },
    payment: {
      submit: "Submit Payment",
      loadingError: "Error - Stripe is not loaded!",
      incorrectCard: "Incorrect card details.",
      completed: "Checkout completed!",
      server: "Error - Server returned an error!",
      unexpected: "Error - Unexpected server response.",
      unidentified: "Unidentified error!",
      toPay: "Amount to pay",
      billingAddress: "Billing Address",
      saveBilling: "Save billing address",
      saveCard: "Save card",
      useSavedCard: "Use saved card starting with {lastDigits}",
      newCard: "Use new card",
    },
    page: {
      registration: "Not yet client",
      passwordReset: "Forgotten password",
    },
    messages: {
      loadMore: "Load More",
    },
    navBar: {
      greetings: "Hi, {name}!",
      signIn: "Sign In",
      profile: "My Profile",
      messages: "Messages",
      signOut: "Sign Out",
      agency: "My Agency",
      support: "Contact the support",
    },
    travel: {
      dateError: "travel start date must be prior to travel end date",
      appError: "all client must use the same message app",
      stopDateError: "Start date must be prior to end date",
      stopStartDateError:
        "Travel start date must be prior to stopover start date",
      stopEndDateError: "stopover end date must be prior to travel end date",
      twoDaysPrior: "you can not book a travel less than 2 days before",
      optionError: "You can only choose at most one of the two options",
    },
    notification: {
      created: "Your {object} has been created",
      updated: "{object} updated!",
      deleted: "{object} deleted!",
      uploaded: "{object} uploaded!",
      validated: "{object} validated !",
      saved: "{object} saved !",
      mail: "E-mail address",
      contract: "contract",
      affiliation_link: "affiliation link",
      agency: "agency",
      travel: "travel",
      profile: "profile",
      clientProfile: "client profile",
      employee: "employee",
      travelerProfile: "traveler profile",
      roadmap: "roadmap",
      sent: "{object} sent!",
      resetEmail: "reset email",
    },
    contract: {
      testContract1:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam volutpat commodo sed egestas egestas. Ut sem viverra aliquet eget sit amet. Leo a diam sollicitudin tempor id eu nisl nunc mi. Tortor at auctor urna nunc. Pharetra diam sit amet nisl suscipit adipiscing bibendum. Eget aliquet nibh praesent tristique magna sit amet. Orci sagittis eu volutpat odio facilisis. Ut tellus elementum sagittis vitae et. Tortor at auctor urna nunc id cursus metus. Habitant morbi tristique senectus et netus et malesuada fames.",
      testContract2:
        "Dignissim cras tincidunt lobortis feugiat. Commodo nulla facilisi nullam vehicula ipsum a. Vitae auctor eu augue ut. Malesuada bibendum arcu vitae elementum curabitur vitae. Habitant morbi tristique senectus et netus et malesuada fames. Dolor sed viverra ipsum nunc aliquet bibendum enim facilisis gravida. Eget mauris pharetra et ultrices neque ornare aenean euismod. Ultrices neque ornare aenean euismod elementum nisi. Etiam sit amet nisl purus in mollis nunc sed id. Feugiat in fermentum posuere urna nec tincidunt. Odio euismod lacinia at quis risus sed. Velit aliquet sagittis id consectetur purus ut faucibus pulvinar. In pellentesque massa placerat duis ultricies. Varius vel pharetra vel turpis nunc. Dictum sit amet justo donec. Ut morbi tincidunt augue interdum velit euismod. Eget gravida cum sociis natoque penatibus. Amet venenatis urna cursus eget nunc scelerisque viverra mauris in. Congue nisi vitae suscipit tellus mauris a diam maecenas sed.",
      testContract3:
        "Mi bibendum neque egestas congue quisque egestas diam. Commodo elit at imperdiet dui accumsan sit amet nulla. Mi quis hendrerit dolor magna eget. Hac habitasse platea dictumst vestibulum rhoncus est. Nunc mattis enim ut tellus elementum sagittis vitae. Amet aliquam id diam maecenas. Amet purus gravida quis blandit turpis cursus in hac habitasse. Sed viverra ipsum nunc aliquet bibendum enim. Sit amet cursus sit amet dictum sit. Suspendisse ultrices gravida dictum fusce ut placerat. Sed faucibus turpis in eu mi bibendum neque.",
      testContract4:
        "Dolor morbi non arcu risus quis varius quam quisque id. Nec nam aliquam sem et tortor consequat id. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum. Sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus. Enim lobortis scelerisque fermentum dui faucibus in ornare quam. Etiam tempor orci eu lobortis elementum nibh. Et malesuada fames ac turpis. Ultrices gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Scelerisque purus semper eget duis at tellus at. Sed viverra tellus in hac habitasse platea. Nisl pretium fusce id velit ut. A erat nam at lectus urna duis. In egestas erat imperdiet sed. Leo urna molestie at elementum. Diam volutpat commodo sed egestas. Euismod lacinia at quis risus sed vulputate odio ut. Ac tortor vitae purus faucibus ornare suspendisse sed. Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis. Feugiat in fermentum posuere urna nec tincidunt praesent semper. Semper feugiat nibh sed pulvinar proin gravida hendrerit lectus.",
      testContract5:
        "Arcu non sodales neque sodales ut etiam sit amet nisl. Hac habitasse platea dictumst quisque sagittis purus. In hac habitasse platea dictumst vestibulum. Sagittis nisl rhoncus mattis rhoncus urna neque. Aliquet risus feugiat in ante. Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. Imperdiet sed euismod nisi porta lorem mollis aliquam ut porttitor. Non pulvinar neque laoreet suspendisse interdum consectetur libero id faucibus. Enim nunc faucibus a pellentesque. Diam maecenas sed enim ut sem. Nunc aliquet bibendum enim facilisis. Nibh ipsum consequat nisl vel. Eget nullam non nisi est sit amet. Id interdum velit laoreet id donec ultrices tincidunt.",
    },
  },
  "en-GB": {
    alert: {
      irreversible: "This action is irreversible, are you sure?",
    },
    error: {
      body401: "You're not allowed to access these data",
      title401: "401 Error",
      body403: "Forbidden Request",
      title403: "403 Error",
      emailCheck: "Email address must match",
      invalidEmail: "Please enter a valid email address",
      underaged: "You must be over 18 to register",
    },
    label: {
      noClient: "You must add at least one traveler to this travel.",
      addedClient: "Clients added to the trip",
      name: "Name",
      surname: "Surname",
      birthdate: "Birthdate",
      email: "Email",
      emailConfirmation: "Email Confirmation",
      phone: "Phone",
      emergencyNumber: "Emergency Number",
      website: "Website",
      language: "Language",
      identifier: "Identifier",
      agency: "Agency",
      travel: "Travels",
      voucher: "Voucher",
      code: "Code",
      gender: "Gender",
      male: "Male",
      female: "Female",
      username: "Username",
      login: "Login",
      date: "Date",
      time: "time",
      agencyName: "Agency Name",
      agencyEmail: "Agency Email (can be the same as Agency director's one)",
      others: "Others",
      other: "Other",
      information: "Information",
      TATchat: "TravelAssist.io tchat",
      geniusTravelTchat: "Genius travel tchat",
      zipCode: "zipCode",
      line: "line {number}",
      browse: "Browse",
      color: "Color {number}",
      fontColor: "Font Color",
      agencyPic: "Select Picture",
      change: "Change",
      create: "Create {object}",
      company: "Company Name",
      vatNumber: "VAT Number",
      progMessages: "Programmed Messages",
      deliveryDate: "Delivery Date",
      informationSaved: "Your information have been saved",
      preAgencyExplanations1:
        "A member of our team will validate your agency soon, you'll receive an email with your contract once it will be done. See you soon :)",
      preAgencyExplanations2: "You will redirected to our home page in",
      secondsDot: "seconds...",
      RaCheck:
        "By checking this box you acknowledge having read and agreeing with our",
      tou: "TOU",
      contract: "Partnership contract for tourism companies",
      thanksContract: "We're happy to continue our partnership with you!",
      contractExplanations:
        "You'll be able to find your contract anytime et this url:",
      tac: "Terms and conditions",
      legalNotices: "Legal Notices",
      privacyPolicy: "Privacy Policy",
      mailValidation: "Email validated on ",
      administratorAccount: "Agency administrator account",
      agencyInformation: "Agency information",
      contractInformation: "Information needed to draw up your contract",
      askForOffers: "Make a request to take advantage of promotional offers",
      contractForm: {
        society: "Society name",
        statut: "Statut",
        capital: "Capital",
        address: "Address",
        zip: "Zip Code",
        city: "Society City",
        registerPlace: "Company register place",
        registerId: "Register ID",
        representative: "Representative name",
        country: "Country",
        licence: "Licence Number",
        tvaNumber: "Intra-Community VAT number",
      },
      travelForm: {
        genericInformation: "Generic Information",
        place: "Place",
        thema: "Thema",
        familyVacations: "Family Vacations",
        celebration: "Celebration",
        surprise: "Surprise",
        birthday: "Birthday",
        coupleVacations: "Couple Vacations",
        withFriends: "With Friends",
        music: "Music",
        sport: "Sport",
        discovery: "Discovery",
        startDate: "Start Date",
        endDate: "End Date",
        stopoverStartDate: "Stopover start date",
        stopoverEndDate: "Stopover end date",
        transportation: "Transportation",
        plane: "Plane",
        boat: "Boat",
        car: "Car",
        bus: "Bus",
        train: "Train",
        bike: "Bike",
        walking: "Walking",
        company: "Company",
        number: "Number",
        from: "From",
        to: "To",
        departureDate: "Departure Date",
        departureTime: "Departure Time",
        arrivalDate: "Arrival Date",
        arrivalTime: "Arrival Time",
        roadmap: "See Roadmap",
        uploadRoadmap: "Upload Roadmap",
        messageApp: "Message App",
        accompagnist: "Accompagnist (Press Enter to add)",
        addClientTravel: "Add Client to travel",
        inviteFriends: "Invite you friends to this travel (max: 2)",
        stopover: "Stopover",
        city: "City",
        accomodation: "Accomodation",
        adress: "Address",
        booked: "Booked",
        restaurant: "Restaurant",
        entertainement: "Entertainment",
        transport: "Transport",
        guide: "Guide",
        age: "Age",
        ownApp: "Use your own Agency app for your travel (+9.90€)",
        watchConversation:
          "Watch your client conversation as a ghost user (+3.90€)",
        performance: "Performance",
      },
      travelerProfile: {
        travelerProfile: "My traveler profile",
        comfort: "Comfort",
        simplicity: "Simplicity",
        regular: "Regular Traveler",
        beginning: "It's just the beginning",
        unusualExperiences: "Searching for unusual experiences",
        wellKnown: "Well-known",
        basketball: "See a basketball match",
        unseenPerformance: "Watch an unseen performance",
        discoveries: "Discoveries and visits",
        beachLaze: "Beach and laze",
        culture: "Culture",
        hobbies: "Hobbies",
        sport: "Sport",
        laze: "Laze",
        mostSee: "The most you can see",
        takeTime: "Take your time",
        culinaryExperiences: "Culinary experiences",
        lightHealthy: "Light and healthy food",
        party: "Party all night long",
        earlyBed: "Early in bed to enjoy tomorrow",
        shopping: "Shopping/Souvenirs",
        noShopping: "No shopping during vacations",
        music: "Music",
        licence: "Licence",
        diet: "Diet",
        classical: "Classical",
        jazz: "Jazz",
        rock: "Rock",
        pop: "Pop",
        car: "Car",
        motorbike: "Motorbike",
        boat: "Boat",
        passion: "Passion",
        onePassion:
          "One passion above the other, juste for us to keep it in mind",
        oneSport: "One sport you practice we should know?",
        specificDiet: "Specific diet",
      },
    },
    buttons: {
      save: "Save",
      back: "Back",
      edit: "Edit",
      add: "Add",
      new: "New",
      go: "Go!",
      seeTchat: "See Tchat",
      invite: "Invite!",
      submitVoucher: "Use Voucher",
      delete: "delete",
      downloadContract: "Download Contract",
      noContract: "Send contract information",
      validateContract: "Validate Contract",
    },
    agency: {
      partnerDate: "Partner since {date}",
      connectApp: "Connect {app}",
      isConnected: "{app} is connected!",
      notConnected: "{app} is not connected",
      checkApp: "Check {app} connection",
      administrator: "Administrator",
      employees: "Employees",
      clients: "Clients",
      travel: "Create a new travel for your clients",
      travels: "Travels",
      employee: "employee",
      deleteEmployee: "Delete employee account",
      invoice: "Invoices",
      noContract: "You haven't sign our white mark partership contract",
      waitingValidation:
        "TravelAssist must check your information, you will receive a mail when your contract will be ready.",
    },
    loadMessage: {
      telegramLink: "Generating telegram connection link",
      checkTelegram: "Checking Telegram connection",
      registerEmployee: "Registering Employee",
      createTravel: "Creating Travel",
      clientList: "Retrieving Client List",
      checkout: "Checking your payment",
      deletingEmployee: "Deleting employee",
      saving: "Saving...",
      contractValidated: "Your contract is validated. Creating your agency...",
    },
    password: {
      password: "Password",
      newPassword: "New Password",
      confirmation: "Password Confirmation",
      confirmationMustMatch: "Password and password confirmation must match",
      min8caracters: "Password Must be at least 8 caracters",
      resetPwd: "Reset Password",
      resetPwdInformation:
        "You will be sent a new password by email you'll be able to connect with it",
      currentPassword: "Current Password",
    },
    email: {
      newEmail: "Change email address",
    },
    payment: {
      submit: "Submit Payment",
      loadingError: "Error - Stripe is not loaded!",
      incorrectCard: "Incorrect card details.",
      completed: "Checkout completed!",
      server: "Error - Server returned an error!",
      unexpected: "Error - Unexpected server response.",
      unidentified: "Unidentified error!",
      toPay: "Amount to pay",
      billingAddress: "Billing Address",
      saveBilling: "Save billing address",
      saveCard: "Save card",
      useSavedCard: "Use saved card starting with {lastDigits}",
      newCard: "Use new card",
    },
    page: {
      registration: "Not yet client",
      passwordReset: "Forgotten password",
    },
    messages: {
      loadMore: "Load More",
    },
    navBar: {
      greetings: "Hi, {name}!",
      signIn: "Sign In",
      profile: "My Profile",
      messages: "Messages",
      signOut: "Sign Out",
      agency: "My Agency",
      support: "Contact the support",
    },
    travel: {
      dateError: "travel start date must be prior to travel end date",
      appError: "all client must use the same message app",
      stopDateError: "Start date must be prior to end date",
      stopStartDateError:
        "Travel start date must be prior to stopover start date",
      stopEndDateError: "stopover end date must be prior to travel end date",
      twoDaysPrior: "you can not book a travel less than 2 days before",
      optionError: "You can only choose at most one of the two options",
    },
    notification: {
      created: "Your {object} has been created",
      updated: "{object} updated!",
      deleted: "{object} deleted!",
      uploaded: "{object} uploaded!",
      validated: "{object} validated !",
      saved: "{object} saved !",
      mail: "E-mail address",
      contract: "contract",
      affiliation_link: "affiliation link",
      agency: "agency",
      travel: "travel",
      profile: "profile",
      clientProfile: "client profile",
      employee: "employee",
      travelerProfile: "traveler profile",
      roadmap: "roadmap",
      sent: "{object} sent!",
      resetEmail: "reset email",
    },
    contract: {
      testContract1:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam volutpat commodo sed egestas egestas. Ut sem viverra aliquet eget sit amet. Leo a diam sollicitudin tempor id eu nisl nunc mi. Tortor at auctor urna nunc. Pharetra diam sit amet nisl suscipit adipiscing bibendum. Eget aliquet nibh praesent tristique magna sit amet. Orci sagittis eu volutpat odio facilisis. Ut tellus elementum sagittis vitae et. Tortor at auctor urna nunc id cursus metus. Habitant morbi tristique senectus et netus et malesuada fames.",
      testContract2:
        "Dignissim cras tincidunt lobortis feugiat. Commodo nulla facilisi nullam vehicula ipsum a. Vitae auctor eu augue ut. Malesuada bibendum arcu vitae elementum curabitur vitae. Habitant morbi tristique senectus et netus et malesuada fames. Dolor sed viverra ipsum nunc aliquet bibendum enim facilisis gravida. Eget mauris pharetra et ultrices neque ornare aenean euismod. Ultrices neque ornare aenean euismod elementum nisi. Etiam sit amet nisl purus in mollis nunc sed id. Feugiat in fermentum posuere urna nec tincidunt. Odio euismod lacinia at quis risus sed. Velit aliquet sagittis id consectetur purus ut faucibus pulvinar. In pellentesque massa placerat duis ultricies. Varius vel pharetra vel turpis nunc. Dictum sit amet justo donec. Ut morbi tincidunt augue interdum velit euismod. Eget gravida cum sociis natoque penatibus. Amet venenatis urna cursus eget nunc scelerisque viverra mauris in. Congue nisi vitae suscipit tellus mauris a diam maecenas sed.",
      testContract3:
        "Mi bibendum neque egestas congue quisque egestas diam. Commodo elit at imperdiet dui accumsan sit amet nulla. Mi quis hendrerit dolor magna eget. Hac habitasse platea dictumst vestibulum rhoncus est. Nunc mattis enim ut tellus elementum sagittis vitae. Amet aliquam id diam maecenas. Amet purus gravida quis blandit turpis cursus in hac habitasse. Sed viverra ipsum nunc aliquet bibendum enim. Sit amet cursus sit amet dictum sit. Suspendisse ultrices gravida dictum fusce ut placerat. Sed faucibus turpis in eu mi bibendum neque.",
      testContract4:
        "Dolor morbi non arcu risus quis varius quam quisque id. Nec nam aliquam sem et tortor consequat id. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum. Sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus. Enim lobortis scelerisque fermentum dui faucibus in ornare quam. Etiam tempor orci eu lobortis elementum nibh. Et malesuada fames ac turpis. Ultrices gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Scelerisque purus semper eget duis at tellus at. Sed viverra tellus in hac habitasse platea. Nisl pretium fusce id velit ut. A erat nam at lectus urna duis. In egestas erat imperdiet sed. Leo urna molestie at elementum. Diam volutpat commodo sed egestas. Euismod lacinia at quis risus sed vulputate odio ut. Ac tortor vitae purus faucibus ornare suspendisse sed. Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis. Feugiat in fermentum posuere urna nec tincidunt praesent semper. Semper feugiat nibh sed pulvinar proin gravida hendrerit lectus.",
      testContract5:
        "Arcu non sodales neque sodales ut etiam sit amet nisl. Hac habitasse platea dictumst quisque sagittis purus. In hac habitasse platea dictumst vestibulum. Sagittis nisl rhoncus mattis rhoncus urna neque. Aliquet risus feugiat in ante. Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. Imperdiet sed euismod nisi porta lorem mollis aliquam ut porttitor. Non pulvinar neque laoreet suspendisse interdum consectetur libero id faucibus. Enim nunc faucibus a pellentesque. Diam maecenas sed enim ut sem. Nunc aliquet bibendum enim facilisis. Nibh ipsum consequat nisl vel. Eget nullam non nisi est sit amet. Id interdum velit laoreet id donec ultrices tincidunt.",
    },
  },
  "fr-FR": {
    alert: {
      irreversible: "Cette action est irréversible, êtes-vous sûr?",
    },
    error: {
      body401: "Vous ne pouvez pas accéder à ces données",
      title401: "Erreur 401",
      body403: "Requête non autorisée",
      title403: "Erreur 403",
      emailCheck: "Les adresses email doivent être identiques",
      invalidEmail: "Veuillez renseigner un email valide",
      underaged: "Vous devez avoir plus de 18 ans pour utiliser notre service",
    },
    label: {
      noClient: "Vous devez ajouter au moins un client.",
      addedClient: "Clients ajoutés au voyage",
      name: "Nom",
      surname: "Prénom",
      birthdate: "Date de naissance",
      email: "Email",
      emailConfirmation: "Confirmation de l'adresse email",
      phone: "Téléphone",
      emergencyNumber: "Téléphone d'urgence",
      website: "Site Web",
      language: "Langue",
      identifier: "Identifiant",
      agency: "Agence",
      travel: "Voyage",
      voucher: "Code Promo",
      code: "Code",
      gender: "Genre",
      male: "Homme",
      female: "Femme",
      username: "Nom d'utilisateur",
      login: "Se Connecter",
      date: "Date",
      time: "Heure",
      agencyName: "Nom d'agence",
      agencyEmail:
        "Email d'agence (peut être le même que l'email de l'administrateur)",
      others: "Autres",
      other: "Autre",
      information: "Information",
      TATchat: "TravelAssist.io tchat",
      geniusTravelTchat: "Genius travel tchat",
      zipCode: "Code postal",
      line: "ligne {number}",
      browse: "Parcourir",
      color: "Couleur {number}",
      fontColor: "Couleur de la police",
      agencyPic: "Choisir image",
      change: "Modifier",
      create: "Créer {object}",
      progMessages: "Messages Programmés",
      deliveryDate: "Date d'envoi",
      informationSaved: "Vos informations on été enregistrées",
      preAgencyExplanations1:
        "L'équipe TravelAssist va valider votre compte rapidement, vous recevrez votre contrat par email à ce moment. A bientôt :)",
      preAgencyExplanations2:
        "Vous allez être redirigé vers notre page d'accueil dans",
      secondsDot: "secondes...",
      RaCheck:
        "En cochant cette case vous certifiez avoir lu et être en accord avec",
      tou: "nos CGU",
      contract: "Notre contrat de partenariat avec les entreprises de tourisme",
      thanksContract:
        "Nous sommes heureux de continuer notre partenariat avec vous!",
      contractExplanations:
        "Vous pourrez retrouver ce contrat à n'importe quel moment à cette url:",
      tac: " nos Conditions générales de vente",
      legalNotices: "nos Mentions Légales",
      privacyPolicy: "notre Politique de confidentialité",
      mailValidation: "Email validé le ",
      administratorAccount: "Compte administrateur de l'agence",
      agencyInformation: "Informations relatives à l'agence",
      contractInformation:
        "Informations nécessaires à l'élaboration de votre contrat",
      askForOffers:
        "Faire une demande afin de profiter d'offres promotionnelles",
      contractForm: {
        society: "Nom de Société",
        statut: "Statut",
        capital: "Capital",
        address: "Adresse",
        zip: "Code Postal",
        city: "Ville de la Société",
        registerPlace: "Enregistré au registre de",
        registerId: "Enregistré sous l'ID",
        representative: "Nom du Représentant",
        country: "Pays",
        licence: "Numéro de license",
        tvaNumber: "Numéro TVA Intracommunautaire",
      },
      company: "Nom de l'Entreprise",
      vatNumber: "Numéro TVA",
      travelForm: {
        genericInformation: "Informations génériques",
        place: "Lieu",
        thema: "Theme",
        familyVacations: "Vacances en famille",
        celebration: "Célébration",
        surprise: "Surprise",
        coupleVacations: "Vacances en couple",
        withFriends: "Entre amis",
        music: "Musique",
        sport: "Sport",
        birthday: "Anniversaire",
        discovery: "Découverte",
        startDate: "Date de départ",
        endDate: "Date de fin",
        stopoverStartDate: "Début de l'étape",
        stopoverEndDate: "Fin de l'étape",
        transportation: "Transport",
        plane: "Avion",
        boat: "Bateau",
        car: "Voiture",
        bus: "Bus",
        train: "Train",
        bike: "Vélo",
        walking: "A pieds",
        company: "Compagnie",
        number: "Numéro",
        from: "De",
        to: "à",
        departureDate: "Date de départ",
        departureTime: "Heure de départ",
        arrivalDate: "Date d'arrivée",
        arrivalTime: "Heure d'arrivée",
        roadmap: "Voir le plan de voyage",
        uploadRoadmap: "Téléverser le plan de voyage",
        messageApp: "Application de messagerie",
        accompagnist: "Accompagnateur (appuyer sur Entrée pour ajouter)",
        addClientTravel: "Ajouter un client au voyage",
        inviteFriends: "Invitez vos amis à ce voyage (max 2)",
        stopover: "Etape",
        city: "Ville",
        accomodation: "Hébergement",
        adress: "Adresse",
        booked: "Réservation",
        restaurant: "Restaurant",
        entertainement: "Loisir",
        transport: "Transport",
        guide: "Guide",
        age: "Age",
        ownApp:
          "Utilisez vos propres application de messageries pour ce voyage (+9.90€)",
        watchConversation:
          "Regardez les conversations de vos clients sans pouvoir intervenir (+3.90€)",
        performance: "Spectacle",
      },
      travelerProfile: {
        travelerProfile: "Mon profil voyageur",
        comfort: "Comfort",
        simplicity: "Simplicité",
        regular: "Voyageur régulier",
        beginning: "Ce n'est qu'un début",
        unusualExperiences: "Recherche 'insolite'",
        wellKnown: "Classique et connu",
        basketball: "Voir un match de basket",
        unseenPerformance: "Voir un spectacle inédit",
        discoveries: "Découvertes et visites",
        beachLaze: "Plage et détente",
        culture: "Culture",
        hobbies: "Loisirs",
        sport: "Sport",
        laze: "Détente",
        mostSee: "En voir le plus possible",
        takeTime: "Prendre son temps",
        culinaryExperiences: "Expériences culinaires",
        lightHealthy: "Se nourrir light et détox",
        party: "Fiesta toute la nuit",
        earlyBed:
          "Se coucher tôt pour en profiter le plus possible le lendemain",
        shopping: "Shopping/Souvenirs",
        noShopping: "Pas de courses pendant les vacances",
        music: "Musique",
        licence: "Permis",
        diet: "Régime alimentaire",
        classical: "Classique",
        jazz: "Jazz",
        rock: "Rock",
        pop: "Pop",
        car: "Voiture",
        motorbike: "Moto",
        boat: "Bateau",
        passion: "Passion",
        onePassion:
          "Une passion, par dessus tout, afin de la garder en tête, on ne sait jamais",
        oneSport: "Un sport pratiqué à savoir?",
        specificDiet: "Régime alimentaire",
      },
    },
    buttons: {
      save: "Enregistrer",
      back: "Retour",
      edit: "Modifier",
      add: "Ajouter",
      new: "Nouveau",
      go: "Go!",
      seeTchat: "Voir le tchat",
      invite: "Inviter!",
      submitVoucher: "Utiliser le code promo",
      delete: "supprimer",
      downloadContract: "Télécharger Contrat",
      noContract: "Remplir les information du contrat",
      validateContract: "Valider le contrat",
    },
    agency: {
      partnerDate: "Partenaire depuis {date}",
      connectApp: "Connecter {app}",
      isConnected: "{app} est connecté!",
      notConnected: "{app} n'est pas connecté",
      checkApp: "Vérifier la connection avec {app}",
      administrator: "Administrateur",
      employees: "Employés",
      clients: "Clients",
      travel: "Créer un nouveau voyage pour vos clients",
      travels: "Voyages",
      employee: "employé",
      deleteEmployee: "Supprimer le compte employé",
      invoice: "Factures",
      noContract: "Vous n'avez pas encore signé notre contrat de partenariat !",
      waitingValidation:
        "TravelAssist doit vérifier vos informations, vous recevrez un email lorsque votre contrat sera prêt.",
    },
    loadMessage: {
      telegramLink: "Génération du lien de connection télégram",
      checkTelegram: "Vérification de la connection à Télégram",
      registerEmployee: "Enregistrement de l'employé",
      createTravel: "Création du voyage",
      clientList: "Récupération de la liste des clients",
      checkout: "Vérification de votre paiement",
      deletingEmployee: "Supression de l'employee",
      saving: "Enregistrement...",
      contractValidated:
        "Votre contrat est validé. Création de votre agence...",
    },
    password: {
      password: "Mot de passe",
      newPassword: "Nouveau mot de passe",
      confirmation: "Confirmation du mot de passe",
      confirmationMustMatch:
        "Le mot de passe et sa confirmation doivent être égaux",
      min8caracters: "Le mot de passe doit contenir au moins 8 caractères",
      resetPwd: "Réinitialiser le mot de passe",
      resetPwdInformation:
        "Vous allez recevoir un nouveau mot de passe par email vous pourrez vous connecter avec celui-ci",
      currentPassword: "Mot de passe actuel",
    },
    email: {
      newEmail: "Changer l'adresse email",
    },
    payment: {
      submit: "Payer",
      loadingError: "Erreur - Stripe n'est pas chargé",
      incorrectCard: "Informations de carte incorrectes",
      completed: "Achat effectué!",
      server: "Erreur interne du serveur",
      unexpected: "Erreur - réponse innatendue du serveur",
      unidentified: "Erreur inconnue!",
      toPay: "Montant à payer",
      billingAddress: "Adresse de facturation",
      saveBilling: "Enregistrer l'adresse de facturation",
      saveCard: "Enregistrer le moyen de paiement",
      useSavedCard: "Utiliser la carte finissant par {lastDigits}",
      newCard: "Utiliser une nouvelle carte de paiement",
    },
    page: {
      registration: "Pas encore client",
      passwordReset: "Mot de passe oublié",
    },
    messages: {
      loadMore: "Charger plus",
    },
    navBar: {
      greetings: "Bonjour, {name}!",
      signIn: "Se connecter",
      profile: "Mon profil",
      messages: "Messages",
      signOut: "Se déconnecter",
      agency: "Mon agence",
      support: "Contacter le support",
    },
    travel: {
      dateError:
        "la date de départ du voyage doit être avant la date de fin du voyage",
      appError:
        "tous les clients doivent utiliser la même application de voyage",
      stopDateError: "la date de départ doit être avant la date de fin",
      stopStartDateError:
        "La date de départ du voyage doit être avant la date de début de l'étape",
      stopEndDateError:
        "La date de fin de l'étape doit être avant la date de fin du voyage",
      twoDaysPrior:
        "vous ne pouvez pas réserver un voyage moins de deux jours avant son début",
      optionError:
        "Vous ne pouvez choisir au plus qu'une des deux options pour un voyage",
    },
    notification: {
      created: "{object} a été créé",
      updated: "{object} mis à jour!",
      deleted: "{object} supprimé!",
      uploaded: "{object} téléversé!",
      validated: "{object} validé !",
      saved: "{object} saved !",
      mail: "E-mail",
      contract: "contrat",
      affiliation_link: "lien d'affiliation",
      agency: "agence",
      travel: "voyage",
      profile: "profil",
      clientProfile: "profil client",
      employee: "employé",
      travelerProfile: "profil voyageur",
      roadmap: "roadmap",
      sent: "{object} envoyé!",
      resetEmail: "email de réinitialisation",
    },
  },
  contract: {
    testContract1:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam volutpat commodo sed egestas egestas. Ut sem viverra aliquet eget sit amet. Leo a diam sollicitudin tempor id eu nisl nunc mi. Tortor at auctor urna nunc. Pharetra diam sit amet nisl suscipit adipiscing bibendum. Eget aliquet nibh praesent tristique magna sit amet. Orci sagittis eu volutpat odio facilisis. Ut tellus elementum sagittis vitae et. Tortor at auctor urna nunc id cursus metus. Habitant morbi tristique senectus et netus et malesuada fames.",
    testContract2:
      "Dignissim cras tincidunt lobortis feugiat. Commodo nulla facilisi nullam vehicula ipsum a. Vitae auctor eu augue ut. Malesuada bibendum arcu vitae elementum curabitur vitae. Habitant morbi tristique senectus et netus et malesuada fames. Dolor sed viverra ipsum nunc aliquet bibendum enim facilisis gravida. Eget mauris pharetra et ultrices neque ornare aenean euismod. Ultrices neque ornare aenean euismod elementum nisi. Etiam sit amet nisl purus in mollis nunc sed id. Feugiat in fermentum posuere urna nec tincidunt. Odio euismod lacinia at quis risus sed. Velit aliquet sagittis id consectetur purus ut faucibus pulvinar. In pellentesque massa placerat duis ultricies. Varius vel pharetra vel turpis nunc. Dictum sit amet justo donec. Ut morbi tincidunt augue interdum velit euismod. Eget gravida cum sociis natoque penatibus. Amet venenatis urna cursus eget nunc scelerisque viverra mauris in. Congue nisi vitae suscipit tellus mauris a diam maecenas sed.",
    testContract3:
      "Mi bibendum neque egestas congue quisque egestas diam. Commodo elit at imperdiet dui accumsan sit amet nulla. Mi quis hendrerit dolor magna eget. Hac habitasse platea dictumst vestibulum rhoncus est. Nunc mattis enim ut tellus elementum sagittis vitae. Amet aliquam id diam maecenas. Amet purus gravida quis blandit turpis cursus in hac habitasse. Sed viverra ipsum nunc aliquet bibendum enim. Sit amet cursus sit amet dictum sit. Suspendisse ultrices gravida dictum fusce ut placerat. Sed faucibus turpis in eu mi bibendum neque.",
    testContract4:
      "Dolor morbi non arcu risus quis varius quam quisque id. Nec nam aliquam sem et tortor consequat id. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum. Sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus. Enim lobortis scelerisque fermentum dui faucibus in ornare quam. Etiam tempor orci eu lobortis elementum nibh. Et malesuada fames ac turpis. Ultrices gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Scelerisque purus semper eget duis at tellus at. Sed viverra tellus in hac habitasse platea. Nisl pretium fusce id velit ut. A erat nam at lectus urna duis. In egestas erat imperdiet sed. Leo urna molestie at elementum. Diam volutpat commodo sed egestas. Euismod lacinia at quis risus sed vulputate odio ut. Ac tortor vitae purus faucibus ornare suspendisse sed. Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis. Feugiat in fermentum posuere urna nec tincidunt praesent semper. Semper feugiat nibh sed pulvinar proin gravida hendrerit lectus.",
    testContract5:
      "Arcu non sodales neque sodales ut etiam sit amet nisl. Hac habitasse platea dictumst quisque sagittis purus. In hac habitasse platea dictumst vestibulum. Sagittis nisl rhoncus mattis rhoncus urna neque. Aliquet risus feugiat in ante. Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. Imperdiet sed euismod nisi porta lorem mollis aliquam ut porttitor. Non pulvinar neque laoreet suspendisse interdum consectetur libero id faucibus. Enim nunc faucibus a pellentesque. Diam maecenas sed enim ut sem. Nunc aliquet bibendum enim facilisis. Nibh ipsum consequat nisl vel. Eget nullam non nisi est sit amet. Id interdum velit laoreet id donec ultrices tincidunt.",
  },
};

export default messages;
