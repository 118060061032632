import Vue from "vue";
import Vuex from "vuex";
import apiHandle from "./apiHandle";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    loadingMessage: "",
    status: "",
    token: localStorage.getItem("token") || "",
    userType: localStorage.getItem("userType") || "",
    userId: localStorage.getItem("userId") || "",
    errorType: "",
    tourAgencyId: localStorage.getItem("tourAgencyId") || "",
    travel_password: "",
    travel_id: "",
    supportChannel: localStorage.getItem("supportChannel") || "",
    travelAssistAgencyId: "5eff19fd7b70c6003ff96a71",
    affiliation: localStorage.getItem("affiliation") || "",
    color: {
      color1: localStorage.getItem("color1") || "#00909f",
      color2: localStorage.getItem("color2") || "#9ad3d1",
      color3: localStorage.getItem("color3") || "#034c57",
      fontColor: localStorage.getItem("fontColor") || "#FFFFFF",
    },
    tourAgencyName:
      localStorage.getItem("tourAgencyName") || "My Travel Assistant",
    agencyPic: localStorage.getItem("agencyPic"),
    informationMessage: {
      message: "",
      type: "notification-message",
      updateNumber: 0,
    },
    documentDisplay: false,
    documentDisplayId: "",
    countrySelector: "",
    ticketToolDocSwitch: "Tools",
    name: localStorage.getItem("name") || "",
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, data) {
      state.status = "success";
      state.token = data.token;
      state.userId = data.userId;
      state.userType = data.userType;
      state.errorType = "";
      state.name = data.name;
      if (data.hasOwnProperty("tourAgencyId")) {
        state.tourAgencyId = data.tourAgencyId;
      }
      if (data.hasOwnProperty("supportChannel")) {
        state.supportChannel = data.supportChannel;
      }
      if (data.hasOwnProperty("tourAgencyName")) {
        state.tourAgencyName = data.tourAgencyName
          .replace(/&amp;/gi, "&")
          .replace(/&#x27;/gi, "'");
        document.title =
          state.tourAgencyName + "  - Votre assistant de voyage connecté";
      } else {
        state.tourAgencyName = "TravelAssist.io";
        document.title =
          "TravelAssist.io" + "  - Votre assistant de voyage connecté";
      }
      if (data.hasOwnProperty("color")) {
        state.color = data.color;
      }
      if (data.hasOwnProperty("agencyPic")) {
        state.agencyPic = data.agencyPic;
      }
    },
    auth_error(state, err) {
      state.status = "error";
      if (err.hasOwnProperty("response")) {
        this.dispatch("push_error_message", err.response.data);
      }
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.userType = "";
      state.userId = "";
      state.tourAgencyId = "";
      state.supportChannel = "";
      state.name = "";
      state.color = {
        color1: "#00909f",
        color2: "#9ad3d1",
        color3: "#034c57",
        fontColor: "#FFFFFF",
      };
      state.tourAgencyName = "My Travel Assistant";
      state.agencyPic = null;
      document.title =
        "MyTravelAssistant.io - Votre assistant de voyage connecté";
    },
    set_join_travel(state, data) {
      state.travel_password = data.travel_password;
      state.travel_id = data.travel_id;
    },
    load(state, message) {
      state.loading = true;
      state.loadingMessage = message;
    },
    unload(state) {
      state.loading = false;
      state.loadingMessage = "";
    },
    remove_affiliation(state) {
      state.affiliation = "";
    },
    add_affiliation(state, affiliationLink) {
      state.affiliation = affiliationLink;
    },
    push_notification_message(state, message) {
      state.informationMessage.message = message;
      state.informationMessage.type = "notification-message";
      state.informationMessage.updateNumber += 1;
    },
    push_error_message(state, message) {
      state.informationMessage.message = message;
      state.informationMessage.type = "error-message";
      state.informationMessage.updateNumber += 1;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        apiHandle
          .login(user)
          .then((resp) => {
            commit("auth_success", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error", err);
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("userType");
        localStorage.removeItem("tourAgencyId");
        localStorage.removeItem("supportChannel");
        localStorage.removeItem("tourAgencyName");
        localStorage.removeItem("color1");
        localStorage.removeItem("color2");
        localStorage.removeItem("color3");
        localStorage.removeItem("fontColor");
        localStorage.removeItem("agencyPic");
        localStorage.removeItem("name");
        apiHandle.logout();
        resolve();
      });
    },
    tokenLogin({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        apiHandle
          .getTokenInformation(user)
          .then((resp) => {
            commit("auth_success", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error", err);
            reject(err);
          });
      });
    },
    setJoinTravel({ commit }, data) {
      commit("set_join_travel", data);
    },
    load({ commit }, message) {
      commit("load", message);
    },
    unload({ commit }) {
      commit("unload");
    },
    remove_affiliation({ commit }) {
      localStorage.removeItem("affiliation");
      commit("remove_affiliation");
    },
    add_affiliation({ commit }, affiliationLink) {
      localStorage.setItem("affiliation", affiliationLink);
      commit("add_affiliation", affiliationLink);
    },
    push_notification_message({ commit }, message) {
      commit("push_notification_message", message);
    },
    push_error_message({ commit }, message) {
      commit("push_error_message", message);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    userType: (state) => state.userType,
    userId: (state) => state.userId,
    token: (state) => state.token,
    tourAgencyId: (state) => state.tourAgencyId,
    errorType: (state) => state.errorType,
    travel_password: (state) => state.travel_password,
    travel_id: (state) => state.travel_id,
    supportChannel: (state) => state.supportChannel,
    travelAssistAgencyId: (state) => state.travelAssistAgencyId,
    loadingMessage: (state) => state.loadingMessage,
    loading: (state) => state.loading,
    affiliationLink: (state) => state.affiliation,
    tourAgencyName: (state) => state.tourAgencyName,
    color: (state) => state.color,
    agencyPic: (state) => state.agencyPic,
    informationMessage: (state) => state.informationMessage,
    name: (state) => state.name,
  },
});
